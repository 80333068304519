import React, { useState, useEffect, useContext, useRef } from 'react'
import './HistoryControl.scss'
import { StoreContext } from "../../context/StoreContext"
import { useSignal } from "@preact/signals-react"
import Utility from "../../objects/Utility"

const HistoryControl = ({ historyEvent, hide }) => {
    const { state, actions } = useContext(StoreContext)
    const [debounce, setDebounce] = useState(-1)
    const [numUpdates, setNumUpdates] = useState(0)
    const bounce = () => {
        if (debounce > -1) {
            return true
        }
        let t = setTimeout(() => {
            setDebounce(-1)
        }, [1000])
        setDebounce(t)
        return false
    }
    const sgBucketFrontUndo = useSignal([])
    const sgBucketFrontRedo = useSignal([])
    const sgBucketRearUndo = useSignal([])
    const sgBucketRearRedo = useSignal([])
    const sgSwitchedSides = useSignal(false)
    const [previousFrontObjState, setPreviousFrontObjState] = useState(null)
    const [previousRearObjState, setPreviousRearObjState] = useState(null)
    const [didPop, setDidPop] = useState(false)
    const [lastSide, setLastSide] = useState(null)
    const [switchedSides, setSwitchedSides] = useState(false)
    const sgCounterCleared = useSignal(false)
    const sgLoadedFromApp = useSignal(false)
    const sgLoadedFromFile = useSignal(false)
    const sgLoadedFromSheet = useSignal(false)
    const sgNumRedos = useSignal(0)
    const sgNumUndos = useSignal(0)

    useEffect(() => {
        if (state.counterSideActive) {
            let side = state.counterSideActive.active
            if (side === 'front' && sgCounterCleared.v === true) {
                sgBucketFrontUndo.v = []
                sgBucketFrontRedo.v = []
                sgCounterCleared.v = false
                setNumUpdates(1)
                signalNumUndoRedos(side)
                return
            }
            if (side === 'rear' && sgCounterCleared.v === true) {
                sgBucketRearUndo.v = []
                sgBucketRearRedo.v = []
                sgCounterCleared.v = false
                setNumUpdates(1)
                signalNumUndoRedos(side)
                return
            }
            if (lastSide === null) {
                setLastSide(side)
            }
            if (side !== lastSide && (lastSide === 'front' || lastSide === 'rear')) {
                setLastSide(side)
                sgSwitchedSides.v = true
                setNumUpdates(0)
                if (side === 'front') {
                    signalNumUndoRedos(side)
                }
                if (side === 'rear') {
                    signalNumUndoRedos(side)
                }
                return
            }
            else {
                sgSwitchedSides.v = false
            }
            let alv = state.counterSideActive[side]
            let layersString = JSON.stringify(state.layers)
            let hash = Utility.cyrb53(alv + layersString)
            setNumUpdates(numUpdates + 1)
            if (side === 'front') {
                if (previousFrontObjState === null) {
                    setPreviousFrontObjState({ side, alv, layersString, hash })
                }
                else {
                    if (didPop) {
                        setTimeout(() => {
                            setDidPop(false)
                        }, 100)
                    }
                    else {
                        if (sgSwitchedSides.v === false && numUpdates > 0) {
                            sgBucketFrontRedo.v = []
                            if (hash !== previousFrontObjState.hash && sgLoadedFromApp.v === false && sgLoadedFromFile.v === false) {
                                pushToUndo({ ...previousFrontObjState })
                            }
                            signalNumUndoRedos(side)
                            sgLoadedFromApp.v = false
                            sgLoadedFromFile.v = false
                        }

                    }
                    setPreviousFrontObjState({ side, alv, layersString, hash })

                }
            }
            if (side === 'rear') {
                if (previousRearObjState === null) {
                    setPreviousRearObjState({ side, alv, layersString, hash })
                }
                else {
                    if (didPop) {
                        setTimeout(() => {
                            setDidPop(false)
                        }, 100)
                    }
                    else {
                        if (sgSwitchedSides.v === false && numUpdates > 0) {
                            sgBucketRearRedo.v = []
                            if (sgLoadedFromApp.v === false && sgLoadedFromFile.v === false) {
                                pushToUndo({ ...previousRearObjState })
                            }
                            sgLoadedFromApp.v = false
                            sgLoadedFromFile.v = false
                        }

                    }
                    setPreviousRearObjState({ side, alv, layersString, hash })

                }
            }
        }
    }, [state.counterSideActive])

    useEffect(() => {
        if (state.counterClear) {
            sgCounterCleared.v = true
            actions.counterClear(false)
        }
    }, [state.counterClear])

    useEffect(() => {
        if (state.counterLoadFromApp) {
            sgCounterCleared.v = true
            sgLoadedFromApp.v = true
        }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        if (state.counterLoadFromFile) {
            sgCounterCleared.v = true
            sgLoadedFromFile.v = true
        }
    }, [state.counterLoadFromFile])

    useEffect(() => {
        if (state.counterLoadFromSheet) {
            sgCounterCleared.v = true
            sgLoadedFromSheet.v = true
        }
    }, [state.counterLoadFromSheet])

    const reportCurrentHash = () => {
        let currentHash = Utility.cyrb53(JSON.stringify(state.activeLayerValues) +
            JSON.stringify(state.layers))
    }

    const currentHash = () => {
        let currentHash = Utility.cyrb53(JSON.stringify(state.activeLayerValues) +
            JSON.stringify(state.layers))

        return currentHash
    }

    const reportUndos = () => {
        console.log('**************** front reportUndos ******************')
        let currentHash = Utility.cyrb53(JSON.stringify(state.activeLayerValues) +
            JSON.stringify(state.layers))
        for (let index = sgBucketFrontUndo.v.length - 1; index >= 0; index--) {
            console.log('undo [' + index + ']', sgBucketFrontUndo.v[index].hash)
        }
        console.log('^^^^^^^^^^^^^^^^ front reportUndos ^^^^^^^^^^^^^^^^^^')
        console.log('**************** rear reportUndos ******************')
        currentHash = Utility.cyrb53(JSON.stringify(state.activeLayerValues) +
            JSON.stringify(state.layers))
        for (let index = sgBucketRearUndo.v.length - 1; index >= 0; index--) {
            console.log('undo [' + index + ']', sgBucketRearUndo.v[index].hash)
        }
        console.log('^^^^^^^^^^^^^^^^ rear reportUndos ^^^^^^^^^^^^^^^^^^')
    }

    const pushToRedo = obj => {
        let side = obj.side
        delete obj.side
        if (side === 'front') {
            if (sgBucketFrontRedo.v.length === 0) {
                sgBucketFrontRedo.v.push(obj)
            }
            else {
                let topObj = sgBucketFrontRedo.v[sgBucketFrontRedo.v.length - 1]
                if (topObj.hash !== obj.hash) {
                    sgBucketFrontRedo.v.push(obj)
                }
            }
            signalNumUndoRedos(side)
        }

        if (side === 'rear') {
            if (sgBucketRearRedo.v.length === 0) {
                sgBucketRearRedo.v.push(obj)
            }
            else {
                let topObj = sgBucketRearRedo.v[sgBucketRearRedo.v.length - 1]
                if (topObj.hash !== obj.hash) {
                    sgBucketRearRedo.v.push(obj)
                }
            }
            signalNumUndoRedos(side)
        }
    }

    const signalNumUndoRedos = side => {
        if (side === 'front') {
            sgNumUndos.v = sgBucketFrontUndo.v.length
            sgNumRedos.v = sgBucketFrontRedo.v.length
        }
        if (side === 'rear') {
            sgNumUndos.v = sgBucketRearUndo.v.length
            sgNumRedos.v = sgBucketRearRedo.v.length
        }
    }

    const signalNumRedos = side => {
        if (side === 'front') {
            return sgBucketFrontUndo.v.length
        }
        if (side === 'rear') {
            return sgBucketRearUndo.v.length
        }
    }

    const pushToUndo = obj => {
        let side = obj.side
        delete obj.side
        let topObj = sgBucketFrontUndo.v[sgBucketFrontUndo.v.length - 1]
        if (side === 'front') {
            if (sgBucketFrontUndo.v.length === 0) {
                sgBucketFrontUndo.v.push(obj)
            }
            else {

                if (topObj.hash !== obj.hash) {
                    sgBucketFrontUndo.v.push(obj)
                }
            }

            signalNumUndoRedos(side)
        }

        if (side === 'rear') {
            if (sgBucketRearUndo.v.length === 0) {
                sgBucketRearUndo.v.push(obj)
            }
            else {
                let topObj = sgBucketRearUndo.v[sgBucketRearUndo.v.length - 1]
                if (topObj.hash !== obj.hash) {
                    sgBucketRearUndo.v.push(obj)
                }
            }

            signalNumUndoRedos(side)
        }

    }

    const onUndo = () => {
        let side = state.counterSideActive.active
        if (side === 'front') {
            if (sgBucketFrontUndo.v.length > 0) {
                let poppedHist = sgBucketFrontUndo.v.pop()
                pushToRedo(previousFrontObjState)
                setDidPop(true)

                actions.activeLayerValues(JSON.parse(poppedHist.alv))
                setTimeout(() => {
                    if (poppedHist.layersString !== JSON.stringify(state.layers)) {
                        actions.layers(JSON.parse(poppedHist.layersString))
                    }
                    signalNumUndoRedos(side)
                }, 30)
            }
        }
        if (side === 'rear') {
            if (sgBucketRearUndo.v.length > 0) {
                let poppedHist = sgBucketRearUndo.v.pop()
                pushToRedo(previousRearObjState)
                setDidPop(true)

                actions.activeLayerValues(JSON.parse(poppedHist.alv))
                setTimeout(() => {
                    if (poppedHist.layersString !== JSON.stringify(state.layers)) {
                        actions.layers(JSON.parse(poppedHist.layersString))
                    }
                    signalNumUndoRedos(side)
                }, 30)
            }
        }
    }

    const onRedo = () => {
        let side = state.counterSideActive.active
        if (side === 'front') {
            if (sgBucketFrontRedo.v.length > 0) {
                let poppedHist = sgBucketFrontRedo.v.pop()
                setDidPop(true)
                pushToUndo(previousFrontObjState)
                actions.activeLayerValues(JSON.parse(poppedHist.alv))
                setTimeout(() => {
                    if (poppedHist.layersString !== JSON.stringify(state.layers)) {
                        actions.layers(JSON.parse(poppedHist.layersString))
                    }
                    signalNumUndoRedos(side)
                }, 30)
            }
        }
        if (side === 'rear') {
            if (sgBucketRearRedo.v.length > 0) {
                let poppedHist = sgBucketRearRedo.v.pop()
                setDidPop(true)
                pushToUndo(previousRearObjState)
                actions.activeLayerValues(JSON.parse(poppedHist.alv))
                setTimeout(() => {
                    if (poppedHist.layersString !== JSON.stringify(state.layers)) {
                        actions.layers(JSON.parse(poppedHist.layersString))
                    }
                    signalNumUndoRedos(side)
                }, 30)
            }
        }
    }


    const reportRedos = () => {
        console.log('**************** front reportRedos ******************')
        console.log('numRedos:', sgNumRedos)
        for (let index = sgBucketFrontRedo.v.length - 1; index >= 0; index--) {
            console.log('undo [' + index + ']', sgBucketFrontRedo.v[index].hash)
        }
        console.log('^^^^^^^^^^^^^^^^ front reportRedos ^^^^^^^^^^^^^^^^^^')
        console.log('**************** rear reportRedos ******************')
        for (let index = sgBucketRearRedo.v.length - 1; index >= 0; index--) {
            console.log('undo [' + index + ']', sgBucketRearRedo.v[index].hash)
        }
        console.log('^^^^^^^^^^^^^^^^ rear reportRedos ^^^^^^^^^^^^^^^^^^')
    }

    useEffect(() => {
        if (state.keyUp) {
            if (state.keyUp === 'u') {
                reportUndos()
            }
            if (state.keyUp === 'r') {
                reportRedos()
            }
            if (state.keyUp === 'c') {
                reportCurrentHash()
            }
        }
        actions.keyUp(null)
    }, [state.keyUp]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClickUndoNum = evt => {
        let side = state.counterSideActive.active
        let listIndex = evt.target.id.replace('undo', '')
        listIndex = Number(listIndex)
        let hist = null
        if (side === 'front') {
            hist = sgBucketFrontUndo.v[listIndex]
        }
        if (side === 'rear') {
            hist = sgBucketRearUndo.v[listIndex]
        }
        if (hist) {
            setDidPop(true)
            actions.activeLayerValues(JSON.parse(hist.alv))
            setTimeout(() => {
                if (hist.layersString !== JSON.stringify(state.layers)) {
                    actions.layers(JSON.parse(hist.layersString))
                }
                signalNumUndoRedos(side)
            }, 30)
        }
    }

    const onClickRedoNum = evt => {
        let side = state.counterSideActive.active
        let listIndex = evt.target.id.replace('redo', '')
        listIndex = Number(listIndex)
        let hist = null
        if (side === 'front') {
            hist = sgBucketFrontRedo.v[listIndex]
        }
        if (side === 'rear') {
            hist = sgBucketRearRedo.v[listIndex]
        }
        if (hist) {
            setDidPop(true)
            actions.activeLayerValues(JSON.parse(hist.alv))
            setTimeout(() => {
                if (hist.layersString !== JSON.stringify(state.layers)) {
                    actions.layers(JSON.parse(hist.layersString))
                }
                signalNumUndoRedos(side)
            }, 30)
        }
    }

    return (
        <div className={hide ? 'display-none' : 'history-control'}>
            {[...Array(30).keys()].map(num => <div id={`undo${29 - num}`} key={num} className={`indicator ${30 - num <= sgNumUndos.v ? 'undoLight' : ''}`} onClick={30 - num <= sgNumUndos.v ? onClickUndoNum : null}>{num}</div>)}
            <div className={sgNumUndos.v > 0 ? '' : 'disabled'} onClick={sgNumUndos.v > 0 && bounce() ? onUndo : null}>undo</div>
            <div className={sgNumRedos.v > 0 ? '' : 'disabled'} onClick={sgNumRedos.v > 0 && bounce() ? onRedo : null}>redo</div>
            {[...Array(30).keys()].map(num => <div id={`redo${num}`} key={num} className={`indicator ${num < sgNumRedos.v ? 'redoLight' : ''}`} onClick={num <= sgNumRedos.v ? onClickRedoNum : null}>{num}</div>)}
        </div>
    );
}
export default HistoryControl
