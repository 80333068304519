import React, { useContext, useState, useEffect } from 'react'
import { useSignal } from "@preact/signals-react"
import Sheet from '../Sheet/Sheet'
import TopBar from '../TopBar/TopBar'
import LayersMenu from '../LayersMenu/LayersMenu'
import LayersDisplay from '../LayersDisplay/LayersDisplay'
import ColorPicker from "../ColorPicker/ColorPicker"
import CounterArea from "../CounterArea/CounterArea"
import DexieBusyOverlay from "../DexieBusyOverlay/DexieBusyOverlay"
import TopMenuView from "../TopMenuView/TopMenuView"
import PopupInfo from "../PopupInfo/PopupInfo"
import ErrorMessages from "../ErrorMessages/ErrorMessages"
import Overlay from "../Overlay/Overlay"
import './Home.scss'
import Utility from "../../objects/Utility"
import Dexie from 'dexie';
import ApiHelper from "../../objects/ApiHelper"
import { StoreContext } from "../../context/StoreContext";

const Home = () => {
    const controller = new AbortController();
    const { signal } = controller;
    const [startApp, setStartApp] = useState(false)
    const mediaQuery = window.matchMedia('(min-width: 1800px)')
    const { state, actions } = useContext(StoreContext)
    const [tabActive, setTabActive] = useState('menu')
    const signalDragSubscribers = useSignal([])
    const signalReloadPrevention = useSignal([])
    const signalDexieActive = useSignal(false)
    const signalWindowWidthHeight = useSignal({ width: -1, height: -1 })
    const [loadingSource, setLoadingSource] = useState(false)
    const [historyEvent, setHistoryEvent] = useState(null)
    const [historyUndoRedoAvailableFront, setHistoryUndoRedoAvailableFront] = useState({ undo: false, redo: false })
    const [historyUndoRedoAvailableRear, setHistoryUndoRedoAvailableRear] = useState({ undo: false, redo: false })
    const [currentSide, setCurrentSide] = useState(null)
    const [resetHistorySequences, setResetHistorySequences] = useState(false)
    const [layersOveride, setLayersOveride] = useState(null)
    const sgDexieToStateCompleted = useSignal(
        {
            layers: false,
            layersOpen: false,
            activeLayerValues: false,
            counterSideActive: null,
            savedCounters: false,
            savedSheets: false,
            slots: false,
            svgs: false,
            sheetSettings: false,
            fonts: false,
            counterSideActive: false,
            sheetSideActive: false
        }
    )

    const sgApiToStateCompleted = useSignal(
        {
            layers: false,
            layersOpen: false,
            activeLayerValues: false,
            counterSideActive: null,
            savedCounters: false,
            savedSheets: false,
            slots: false,
            svgs: false,
            sheetSettings: false,
            fonts: false,
            counterSideActive: false,
            sheetSideActive: false
        }
    )

    const sgStateToDexieCompleted = useSignal(
        {
            layers: false,
            layersOpen: false,
            activeLayerValues: false,
            counterSideActive: null,
            savedCounters: false,
            savedSheets: false,
            slots: false,
            svgs: false,
            sheetSettings: false,
            fonts: false,
            counterSideActive: false,
            sheetSideActive: false
        }
    )

    const [dexieStartupCounts, setDexieStartupCounts] = useState([
        { name: 'activeLayerValues', expectedCountMin: 1, count: 0 },
        { name: 'layers', expectedCountMin: 15, count: 0 },
        { name: 'svgs', expectedCountMin: 126, count: 0 },
        { name: 'sheetSettings', expectedCountMin: 1, count: 0 },
        { name: 'fonts', expectedCountMin: 1, count: 0 }
    ])

    // first string value is the primaryKey. The rest are the regular keys. dexie schema
    const dexieSchema = {
        layers: ['layerKey', 'layerKey', 'layerName', 'layerHidden', 'layerOpacity', 'inputs', 'layerInputKeys', 'layerOrder', 'parentLayerKey', 'layerActive', 'layerActiveRequiredInputKey'],
        svgs: ['svgKey', 'svgKey', 'svgName', 'svgCode', 'uniquePrepend', 'viewBox'],
        sheetSettings: ['index', 'index', 'counterSize', 'counterCustomSize', 'counterMargins', 'countersColumnsRows', 'pageMargins', 'useCounterCustomSize', 'guideType', 'printableArea', 'sheetSide'],
        slots: ['number', 'number', 'xy', 'centerXy', 'pixelsWidth', 'position', 'counterState'],
        activeLayerValues: ['lik', 'lik', 'value'],
        savedCounters: [
            'hash',
            'hash',
            'name',
            'layers',
            'counterSideActive',
            'layerKeysOrdered',
            'customImageSvgKeys',
            'customSvgKeys',
            'fonts',
            'layerSvgs',
            'dateSaved'],
        savedSheets: ['hash', 'hash', 'name', 'slots', 'sheetSettings', 'compressedSvg', 'fonts', 'dateSaved'],
        fonts: ['fontFamily+fontStyle+fontWeight', 'fontFamily', ' fontStyle', 'fontWeight', 'fontSrc', 'fontUrl', 'fontUnicodeRange', 'fontText'],
        layersOpen: ['layerKey', 'layerKey', 'layerOpen'],
        counterSideActive: ['index', 'index', 'active', 'front', 'rear'],
        sheetSideActive: ['active', 'active', 'front', 'rear'],
    }

    const sgLoadSequence = useSignal(
        [
            { dataName: "layers", uri: "layer/cache2", apiData: null, dexieData: null },
            { dataName: "layersOpen", uri: null, apiData: null, dexieData: null },
            { dataName: "activeLayerValues", uri: null, apiData: null, dexieData: null },
            { dataName: "counterSideActive", uri: null, apiData: null, dexieData: null },
            { dataName: "savedCounters", uri: null, apiData: null, dexieData: null },
            { dataName: "savedSheets", uri: null, apiData: null, dexieData: null },
            { dataName: "slots", uri: null, apiData: null, dexieData: null },
            { dataName: "svgs", uri: "svg/get", apiData: null, dexieData: null },
            { dataName: "sheetSettings", uri: "/sheetSettings/get", apiData: null, dexieData: null },
            { dataName: "fonts", uri: "font/get", apiData: null, dexieData: null },
            { dataName: "counterSideActive", uri: null, apiData: null, dexieData: null },
            { dataName: "sheetSideActive", uri: null, apiData: null, dexieData: null },
        ]
    )

    const processRestoreDatabase = async () => {
        if (state.restoreDatabase) {
            setStartApp(false)
            for (const [key] of Object.entries(dexieSchema)) {
                await state.dexie[key].clear()
            }

            window.location.reload()
        }
    }

    const clearDexie = async () => {
        for (const [key] of Object.entries(dexieSchema)) {
            await state.dexie[key].clear()
        }

    }

    useEffect(() => {
        if (startApp) {
            actions.windowResize({ width: window.innerWidth, height: window.innerHeight })
            actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
            signalWindowWidthHeight.v = { width: window.innerWidth, height: window.innerHeight }
            storagePersist()
        }
    }, [startApp])

    useEffect(() => {
        if (state.restoreDatabase) {
            // this comes from the AppMenu option 'Reset database'. The AppMenu makes sure the server is
            // reachable, and if so calls actions.restoreDatabase(true)
            processRestoreDatabase()
        }
    }, [state.restoreDatabase])

    useEffect(() => {
        if (state.importDatabase) {
            actions.topMenuView(null)
            setStartApp(false)
            importDataToDexie(state.importDatabase)
        }
    }, [state.importDatabase])

    const importDataToDexie = async (dataBackup) => {
        await clearDexie()
        for (let d = 0; d < dataBackup.length; d++) {
            await state.dexie[dataBackup[d].store].bulkPut(dataBackup[d].data)
        }
        window.location.reload()
    }

    useEffect(() => {
        if (state.processImportDatabase) {
            window.location.reload()
        }
    }, [state.processImportDatabase])

    useEffect(() => {
        if (startApp) {
            adjustHeightLeftSide()
        }
    }, [state.windowWidthHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    const adjustHeightLeftSide = () => {
        let elsLeft = document.getElementsByClassName('td-cmenu')
        let elsRight = document.getElementsByClassName('drawing-area')
        let heightLeft = 0
        let heightRight = 0
        if (elsLeft.length > 0) {
            let menuBox = elsLeft[0].getBoundingClientRect()
            heightLeft = Utility.roundFloat(menuBox.height)
        }
        if (elsRight.length > 0) {
            let drawingAreaBox = elsRight[0].getBoundingClientRect()
            heightRight = Utility.roundFloat(drawingAreaBox.bottom, 1)
        }
        let diff = heightRight - (heightLeft + 30)
        if (diff > 0) {
            let newHeightForLeft = heightLeft + diff
            elsLeft[0].style.height = (newHeightForLeft) + 'px'
        }
        else {
            if (diff < 0) {
                let newHeightForLeft = heightLeft + diff
                elsLeft[0].style.height = (newHeightForLeft) + 'px'
            }
        }
    }

    useEffect(() => {

        var errorReport = function (errors) {
            console.warn('Caught!', errors);
        }

        window.addEventListener('error', function (e) {
            var ie = window.event || {};
            var errMsg = e.message || ie.errorMessage || "404 error on " + window.location;
            var errSrc = (e.filename || ie.errorUrl) + ': ' + (e.lineno || ie.errorLine);
            errorReport([errMsg, errSrc, e]);
        }, true);

        const handleMouseUp = e => {
            actions.mouseClick(e)
        }
        document.addEventListener("mouseup", handleMouseUp, { signal });

        const handleTouchStart = e => {
            actions.mouseClick(e)
        }
        document.addEventListener("touchstart", handleTouchStart, { signal });

        const handleMouseDown = e => {
            actions.mouseDown(e)
        }
        document.addEventListener("mousedown", handleMouseDown, { signal });

        const handleDblClick = e => {
            actions.mouseDblClick(e)
        }
        document.addEventListener("dblclick", handleDblClick, { signal });

        const handleKeyUp = e => {
            actions.keyUp(e.key)
        }
        document.addEventListener("keyup", handleKeyUp, { signal });

        const handleResize = () => {
            actions.windowResize({ width: window.innerWidth, height: window.innerHeight })
            actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
            let ele = document.getElementById('counter')
            if (ele) {
                actions.counterDrawWidth(ele.offsetWidth);
            }
        }
        window.addEventListener('resize', () => handleResize(), { signal });

        new ResizeObserver(() => handleResize()).observe(document.getElementById('root'));

        mediaQuery.addEventListener('change', (e) => actions.layoutInline(e.matches))
        // Initial check
        actions.layoutInline(mediaQuery.matches)

        const onScroll = () => {
            actions.scrollTop(window.scrollY)
            actions.scrollLeft(window.scrollX)
        }

        document.addEventListener("scroll", onScroll, { signal });
        onScroll()
        setupDexie()

        return () => {
            controller.abort();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.keyUp) {
            if (state.keyUp === 'a') {
                console.log('state.activeLayerValues: ', state.activeLayerValues)
            }
            if (state.keyUp === '2') {
                console.log('state.activeLayerValues: ', state.activeLayerValues)
                console.log('state.counterSideActive:', state.counterSideActive)
                console.log('front:', JSON.parse(state.counterSideActive.front))
                console.log('rear:', JSON.parse(state.counterSideActive.rear))
            }
            if (state.keyUp === '3') {
                console.log('state.slots[0]: ', state.slots[0])
            }
            if (state.keyUp === '5') {
                // console.log('slot 0 svg: ', state.slots[0].counterState.frontSvg)
            }
            if (state.keyUp === '<') {
                //         console.log('undo')
                //        counterUndoRedo('undo')
            }
            if (state.keyUp === '>') {
                //        console.log('redo')
                //        counterUndoRedo('redo')
            }
            // if (state.keyUp === 'f') {
            //     state.counterSideActiveHistoryFront.forEach((hist, index) => {
            //         let newAlv = JSON.parse(hist.alv)
            //         let parsedLayers = JSON.parse(hist.layers)
            //         let cmbLayer = parsedLayers.find(hy => hy.layerKey === 10)
            //         console.log('front ' + index + ') sequence:', hist.sequence, 'ancestor:',
            //             hist.ancestor, 'arrivedAt:', hist.arrivedAt, 'combat value:', newAlv['10_60'],
            //             'combat visible:', cmbLayer.layerActive)
            //     })
            // }
            // if (state.keyUp === 'r') {
            //     state.counterSideActiveHistoryRear.forEach((hist, index) => {
            //         let newAlv = JSON.parse(hist.alv)
            //         console.log('rear ' + index + ') sequence:', hist.sequence, 'ancestor:',
            //             hist.ancestor, 'arrivedAt:', hist.arrivedAt, 'combat value:', newAlv['10_60'])
            //     })
            // }
        }
        actions.keyUp(null)
    }, [state.keyUp]) // eslint-disable-line react-hooks/exhaustive-deps

    const storagePersist = () => {
        if (navigator.storage && navigator.storage.persist) {
            navigator.storage.persist().then(persistent => {
                if (persistent) {
                    console.warn("Storage will not be cleared except by explicit user action");
                } else {
                    if (navigator.storage.persisted) {
                        navigator.storage.persisted().then(function (persistent) {
                            if (persistent)
                                console.warn("Storage will not be cleared except by explicit user action.");
                            else
                                console.warn("Storage may be cleared by the UA under storage pressure.");
                        });
                    }
                }
            });
        }
    }

    const measureStorageUsage = () => {
        navigator.storage.estimate().then(result => {
            let usage = result.usage
            if (result.quota > 0) {
                let percentageUsed = result.usage / result.quota
                percentageUsed = Utility.roundFloat((percentageUsed * 100), 1) + '%'
                actions.storageUsage({ usage: result.usage, quota: result.quota, percentage: percentageUsed })
            }
        })
    }

    useEffect(() => {
        if (state.dexie && !state.restoreDatabase) {
            dexieHasData() //check to see if we load from dexie or from api
        }
    }, [state.dexie]) // eslint-disable-line react-hooks/exhaustive-deps

    const dexieHasData = async () => {
        let startupCounts = JSON.parse(JSON.stringify(dexieStartupCounts))
        for (let d = 0; d < startupCounts.length; d++) {
            let dexieStartupCount = dexieStartupCounts[d]
            if (state.dexie) {
                startupCounts[d].count = await state.dexie[dexieStartupCount.name].count()
            }
        }
        setDexieStartupCounts(startupCounts)
        let doApiLoad = false
        startupCounts.forEach(sc => {
            if (sc.count < sc.expectedCountMin) {
                doApiLoad = true
            }
        })
        if (doApiLoad) {
            setLoadingSource('api')
        }
        else {
            setLoadingSource('dexie')
        }

    }

    useEffect(() => {
        if (loadingSource === 'dexie') {
            loadFromDexie()
        }
        if (loadingSource === 'api') {
            loadFromApi()
        }
    }, [loadingSource])

    const loadFromDexie = async () => {
        if (state.restoreDatabase) {
            return
        }
        for (const [key, value] of Object.entries(dexieSchema)) {
            await dexieLoad(key)
        }
        setLoadingSource(null)
        actions.importDatabase(null)
        setStartApp(true)
    }

    useEffect(() => {
        if (startApp) {
            measureStorageUsage()
        }
    }, [startApp])

    const dexieLoad = async key => {
        let data = await state.dexie[key].toArray()
        if (key === 'activeLayerValues') {
            let objData = stateActiveLayerValuesDexieToObj(data)
            actions[key](objData)
        }
        if (key === 'layers') {
            const orderedLayers = data.sort((a, b) => a.layerOrder - b.layerOrder)
            actions[key](orderedLayers)
        }
        if (key === 'sheetSettings') {
            if (Array.isArray(data)) {
                data = data[0]
            }
            actions[key](data)
        }
        if (key === 'counterSideActive') {
            if (data.length > 0) {
                let obj = data[0]
                if (obj.front) {
                    if (typeof obj.front === 'object') {
                        obj.front = JSON.stringify(obj.front)
                    }
                }
                else {
                    obj.front = '{1_38: 0, 1_181:\'{"fillType":"solid","fillColor":"#11FF33","gradientDirection":"linear","xyPositionStart":"0,0","xyPositionEnd":"1,1","colorStart":"#00FF00","colorMid":"#44ee44","colorEnd":"#66ff66"}\'}'
                }
                if (obj.rear) {
                    if (typeof obj.rear === 'object') {
                        obj.rear = JSON.stringify(obj.rear)
                    }
                }
                else {
                    obj.rear = '{1_38: 0, 1_181:\'{"fillType":"solid","fillColor":"#11FF33","gradientDirection":"linear","xyPositionStart":"0,0","xyPositionEnd":"1,1","colorStart":"#00FF00","colorMid":"#44ee44","colorEnd":"#66ff66"}\'}'
                }
                actions[key](obj)
            }
        }
        if (key.startsWith('saved')) {
            let objData = hashedArrayToObj(data)
            if (!Utility.emptyCheck(objData)) {
                actions[key](objData)
            }
            else {
                // mark as done
                dexieLoadToStateConfirm(key)
            }
        }
        if (key === 'svgs' || key === 'slots' || key === 'fonts' || key === 'layersOpen') {
            actions[key](data)
        }
    }

    // some of the calls here are made from the above function if there is no data for the store.
    // Otherwise, the call to dexieLoadToStateConfirm comes from the useEffect state.storeNames methods.
    // when it confirms it has data loaded for that state name.
    const dexieLoadToStateConfirm = name => {
        sgDexieToStateCompleted.value[name] = true
        if (Object.values(sgDexieToStateCompleted.value).every(item => item)) {
            setLoadingSource(null)
            setStartApp(true)
        }
    }

    const apiLoadToStateConfirm = name => {
        sgApiToStateCompleted.value[name] = true
        if (Object.values(sgApiToStateCompleted.value).every(item => item)) {
            processStateToDexie()
        }
    }

    const processStateToDexie = () => {
        let name = ''
        let names = Object.keys(sgApiToStateCompleted.value)
        for (let i = 0; i < names.length; i++) {
            name = names[i]
            let data = state[name]
            if (data) {
                if ((Array.isArray(data) && data.length > 0) ||
                    Utility.isObjectNotArray(data) && Object.keys(data).length > 0) {
                    if (name === 'layers') {
                        if (data.length > 0) {
                            preventReload('layers')
                            state.dexie['layers'].bulkPut(data).then(() => {
                                reportStateToDexieLoadCompleted('layers')
                                releasePreventReload('layers')
                            })
                        }
                    }
                    if (name === 'svgs') {
                        if (state.svgs.length > 0) {
                            preventReload('svgs')
                            state.dexie['svgs'].bulkPut(data).then(() => {
                                reportStateToDexieLoadCompleted('svgs')
                                releasePreventReload('svgs')
                            })
                        }
                    }
                    if (name === 'sheetSettings') {
                        if (Object.keys(state.sheetSettings).length > 0) {
                            preventReload('sheetSettings')
                            state.dexie['sheetSettings'].bulkPut([data]).then(() => {
                                reportStateToDexieLoadCompleted('sheetSettings')
                                releasePreventReload('sheetSettings')
                            })
                        }
                    }
                    if (name === 'fonts') {
                        if (state.fonts.length > 0) {
                            preventReload('fonts')
                            state.dexie['fonts'].bulkPut(data).then(() => {
                                reportStateToDexieLoadCompleted('fonts')
                                releasePreventReload('fonts')
                            })
                        }
                    }

                }
            }
        }
    }

    const reportStateToDexieLoadCompleted = name => {
        sgStateToDexieCompleted.value[name] = true
        if (Object.values(sgStateToDexieCompleted.value).every(item => item)) {
            setLoadingSource(null)
            setStartApp(true)
        }
    }

    useEffect(() => {
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('layers')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('layers')
            return
        }
        if (startApp) {
            state.dexie['layers'].clear().then(() => {
                state.dexie['layers'].bulkPut(state.layers)
            })
        }
    }, [state.layers])

    const updateCounterSideActive = alv => {
        let _alv = alv
        if (typeof _alv === 'object') {
            _alv = JSON.stringify(alv)
        }
        let _counterSideActive = JSON.parse(JSON.stringify(state.counterSideActive))

        if (_counterSideActive.active === 'front') {
            _counterSideActive.front = _alv
        }
        else {
            _counterSideActive.rear = _alv
        }
        if (_counterSideActive.rear === '{}') {
            _counterSideActive.rear = _counterSideActive.front
        }
        actions.counterSideActive(_counterSideActive)
    }

    const historyControlChange = change => {
        if (change === 'undo' || change === 'redo') {
            //counterUndoRedo(change)
        }
    }

    const updateCounterSideActiveHistory = () => {
        let historyItem = { alv: '', sequence: -1, ancestor: -1, arrivedAt: null }
        let sideActive = state.counterSideActive.active
        let historyCopy = sideActive === 'front' ? [...state.counterSideActiveHistoryFront] : [...state.counterSideActiveHistoryRear]



        // // the last one will have the highest sequence
        // let sequence = -1
        // if (historyCopy.length > 0) {
        //     sequence = latestHistoryEntry.sequence
        // }
        // sequence++ // increment to get the next sequence number which will be assigned.
        // historyItem.alv = state.counterSideActive[sideActive]
        // historyItem.layers = JSON.stringify(state.layers)
        // historyItem.sequence = sequence
        // historyItem.ancestor = sequence - 1
        // if (historyEvent) {
        //     historyItem.arrivedAt = historyEvent.arrivedAt
        //     historyItem.ancestor = historyEvent.sequence
        // }
        // // else arrivedAt stays at default null, ancestor stays at sequence - 1.

        // if (historyCopy.length > 100) {
        //     historyCopy.shift()
        // }
        // if (sideActive === 'front') {
        //     console.log('calling counterSideActiveHistoryFront')
        //     actions.counterSideActiveHistoryFront([...historyCopy, historyItem])
        // }
        // else {
        //     console.log('counterSideActiveHistoryRear')
        //     actions.counterSideActiveHistoryRear([...historyCopy, historyItem])
        // }

        // setHistoryEvent(null)
    }


    // clear history if user loaded a new counter.
    useEffect(() => {
        if(state.counterLoadFromApp) {
            setResetHistorySequences(true)
        }
    }, [state.counterLoadFromApp])

    // const counterUndoRedo = undoRedo => {
    //     let lookForSide = state.counterSideActive.active
    //     let historyCopy = lookForSide === 'front' ? [...state.counterSideActiveHistoryFront] : [...state.counterSideActiveHistoryRear]
    //     let latestHistory = historyCopy[historyCopy.length - 1]
    //     if (undoRedo === 'undo') {
    //         if (latestHistory.arrivedAt === null) {
    //             let foundHistory = historyCopy.find(hist => hist.sequence === latestHistory.ancestor)
    //             if (foundHistory) {
    //                 setHistoryEvent({ alv: foundHistory.alv, layers: foundHistory.layers, sequence: foundHistory.sequence, arrivedAt: 'undo' })
    //                 return
    //             }
    //         }
    //         if (latestHistory.arrivedAt === 'undo' || latestHistory.arrivedAt === 'redo') {
    //             let foundHistory = historyCopy.find(hist => hist.sequence === (latestHistory.ancestor - 1))
    //             if (foundHistory) {
    //                 setHistoryEvent({ alv: foundHistory.alv, layers: foundHistory.layers, sequence: foundHistory.sequence, arrivedAt: 'undo' })
    //                 return
    //             }
    //         }
    //     }

    //     if (undoRedo === 'redo') {
    //         let nextSequence = latestHistory.ancestor + 1
    //         let redoHist = historyCopy.find(hist => hist.sequence === nextSequence)
    //         setHistoryEvent({ alv: redoHist.alv, layers: redoHist.layers, sequence: nextSequence, arrivedAt: 'redo' })
    //     }

    // }

    // useEffect(() => {
    //     if (historyEvent) {
    //         let alvObj = JSON.parse(historyEvent.alv)
    //         actions.activeLayerValues(alvObj)
    //     }
    // }, [historyEvent])

    const updateLayersActive = (alv, layers) => {
        let _layers = [...layers]
        let changed = false
        for (let i = 0; i < _layers.length; i++) {
            let layer = _layers[i]
            let active = Utility.layerIsInActiveLayerValues(alv, layer.layerKey)
            active = active ? 1 : 0
            if (layer.layerActive !== active) {
                layer.layerActive = active
                changed = true
                _layers[i] = JSON.parse(JSON.stringify(layer))
            }
        }
        if (changed) {
            actions.layers(_layers)
        }
    }

    useEffect(() => {
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('activeLayerValues')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('activeLayerValues')
            return
        }
        if (startApp && state.activeLayerValues) {
            updateCounterSideActive(state.activeLayerValues)

            updateLayersActive(state.activeLayerValues, state.layers)
            

            let dexieCompatible = stateActiveLayerValuesToDexie(state.activeLayerValues)
            if (dexieCompatible && dexieCompatible.length > 0) {
                preventReload('activeLayerValues')
                state.dexie.activeLayerValues.toArray().then(currentData => {
                    let keysToRemove = currentData.filter(cd => !dexieCompatible.find(dc => cd.lik === dc.lik))
                    keysToRemove = keysToRemove.map(mtr => mtr.lik)
                    if (keysToRemove.length > 0) {
                        state.dexie.activeLayerValues.bulkDelete(keysToRemove).then(() => {
                            state.dexie.activeLayerValues.bulkPut(dexieCompatible).then(() => {
                                releasePreventReload('activeLayerValues')
                            })
                        })
                    }
                    else {
                        state.dexie['activeLayerValues'].bulkPut(dexieCompatible).then(() => {
                            releasePreventReload('activeLayerValues')
                        })
                    }
                })
            }
        }
    }, [state.activeLayerValues])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('sheetSettings')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('sheetSettings')
            return
        }
        if (startApp) {
            state.dexie['sheetSettings'].clear().then(() => {
                preventReload('sheetSettings')
                // there should only be one row of sheetSettings in dexie.
                Utility.dexieClearTable(state.dexie, 'sheetSettings').then(
                    state.dexie['sheetSettings'].put(state.sheetSettings).then(() => {
                        releasePreventReload('sheetSettings')
                    })
                )
            })
        }
    }, [state.sheetSettings])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('counterSideActive')
            updateCounterSideActiveHistory()
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('counterSideActive')
            updateCounterSideActiveHistory()
            return
        }

        if (startApp) {
            preventReload('counterSideActive')
            let obj = {
                index: 0,
                active: state.counterSideActive.active,
                front: state.counterSideActive.front,
                rear: state.counterSideActive.rear
            }
            if (obj.rear === '' || obj.rear === '{}') {
                let alv = JSON.parse(state.counterSideActive.front)
                let rearAlv = {}
                for (const [key, value] of Object.entries(alv)) {
                    if (key.startsWith('1_')) {
                        rearAlv[key] = value
                    }
                }
                obj.rear = JSON.stringify(rearAlv)
            }
            state.dexie['counterSideActive'].put(obj).then(() => {
                releasePreventReload('counterSideActive')
            })
            updateCounterSideActiveHistory()
        }
    }, [state.counterSideActive])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('sheetSideActive')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('sheetSideActive')
            return
        }

    }, [state.sheetSideActive])


    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('savedCounters')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('savedCounters')
            return
        }
        if (startApp) {
            if (!Utility.emptyCheck(state.savedCounters)) {

                let values = Object.values(state.savedCounters)

                state.dexie.savedCounters.toArray().then(currentData => {
                    let hashesToRemove = []
                    currentData.forEach(cd => {
                        let found = values.find(sc => sc.hash === cd.hash)
                        if (!found) {
                            hashesToRemove.push(cd.hash)
                        }
                    })

                    if (hashesToRemove.length > 0) {
                        preventReload('savedCounters')
                        state.dexie.savedCounters.bulkDelete(hashesToRemove).then(() => {
                            state.dexie.savedCounters.bulkPut(values).then(() => {
                                releasePreventReload('savedCounters')
                            })
                        })
                    }
                    else {
                        if (values.length > 0) {
                            preventReload('savedCounters')
                            state.dexie.savedCounters.bulkPut(values).then(() => {
                                releasePreventReload('savedCounters')
                            })
                        }
                    }
                })
            }
            else {
                preventReload('savedCounters')
                state.dexie['savedCounters'].clear().then(() => {
                    releasePreventReload('savedCounters')
                })
            }
        }
    }, [state.savedCounters])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('savedSheets')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('savedSheets')
            return
        }
        if (startApp) {
            if (!Utility.emptyCheck(state.savedSheets)) {
                let values = Object.values(state.savedSheets)
                if (values.length > 0) {
                    state.dexie['savedSheets'].clear().then(() => {
                        preventReload('savedSheets')
                        state.dexie['savedSheets'].bulkPut(values).then(() => {
                            releasePreventReload('savedSheets')
                        })
                    })
                }
            }
            else {
                preventReload('savedSheets')
                state.dexie['savedSheets'].clear().then(() => {
                    releasePreventReload('savedSheets')
                })
            }
        }
    }, [state.savedSheets])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('svgs')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('svgs')
            return
        }
        if (startApp) {
            if (state.svgs.length > 0) {
                state.dexie['svgs'].clear().then(() => {
                    preventReload('svgs')
                    state.dexie['svgs'].bulkPut(state.svgs).then(() => {
                        releasePreventReload('svgs')
                    })
                })
            }
        }
    }, [state.svgs])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('slots')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('slots')
            return
        }
        if (startApp) {
            if (state.slots.length > 0) {
                preventReload('slots')
                state.dexie['slots'].toArray().then(dexieData => {
                    if (JSON.stringify(dexieData) !== JSON.stringify(state.slots)) {
                        state.dexie['slots'].clear().then(() => {
                            state.dexie['slots'].bulkPut(state.slots).then(() => {
                                releasePreventReload('slots')
                            })
                        })
                    }
                    else {
                        releasePreventReload('slots')
                    }
                })
            }
        }
    }, [state.slots])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('fonts')
            loadFontsToHead(state.fonts)
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('fonts')
            loadFontsToHead(state.fonts)
            return
        }
        if (startApp) {
            if (state.fonts.length > 0) {
                preventReload('fonts')
                state.dexie['fonts'].clear().then(() => {
                    state.dexie['fonts'].bulkPut(state.fonts).then(() => {
                        releasePreventReload('fonts')
                    })
                })
                loadFontsToHead(state.fonts)
            }
        }
    }, [state.fonts])

    useEffect(() => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (loadingSource === 'dexie') {
            dexieLoadToStateConfirm('layersOpen')
            return
        }
        if (loadingSource === 'api') {
            apiLoadToStateConfirm('layersOpen')
            return
        }
        if (startApp) {
            if (state.svgs.length > 0) {
                preventReload('layersOpen')
                state.dexie['layersOpen'].bulkPut(state.layersOpen).then(() => {
                    releasePreventReload('layersOpen')
                })
            }
        }
    }, [state.layersOpen])

    const loadFromApi = async () => {
        let notSpacerInputs = []
        let data = null
        for (let i = 0; i < sgLoadSequence.value.length; i++) {
            let name = sgLoadSequence.value[i].dataName
            let uri = sgLoadSequence.value[i].uri
            // check if dexie has data for this, despite this being loadFromApi
            let dexieInfo = dexieStartupCounts.find(st => st.name === name)
            if (dexieInfo) {
                if (dexieInfo.count >= dexieInfo.expectedCountMin) {
                    // do dexie instead of api
                    let dexieData = await dexieLoad(name)
                    if (dexieData) {
                        break
                    }
                }
            }
            if (!uri) {
                apiLoadToStateConfirm(name)
                // set this store's entry to completed, since we won't be really loading anything to it.
                reportStateToDexieLoadCompleted(name)
            }
            else {
                // call api
                data = await ApiHelper.getStore({ uri: uri })
                if (name === 'layers') {
                    data.forEach(dt => {
                        dt.parentLayerKey = -1 // something I missed on original design of schema.
                    })
                    // remove spacers from inputs. Spacers not used in version 3.
                    data.forEach(layer => {
                        notSpacerInputs = []
                        layer.inputs.forEach(input => {
                            if (input.named.includes('spacer') || input.inputKey === 5 || input.inputKey === 59 || input.inputKey === 72) {
                                ;
                            }
                            else {
                                notSpacerInputs.push(input)
                            }
                        })
                        layer.inputs = notSpacerInputs
                        layer.layerInputKeys = layer.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
                    })
                }
                if (name === 'sheetSettings') {
                    let sheetSettings = {}
                    sheetSettings.counterCustomSize = parseFloat(data[0].counterCustomSize)
                    if (sheetSettings.counterCustomSize === -1) {
                        sheetSettings.counterCustomSize = ''
                    }
                    sheetSettings.counterSize = parseFloat(data[0].counterSize)
                    sheetSettings.counterMargins = Utility.convertPostgresArrayToArray(data[0].counterMargins)
                    sheetSettings.pageMargins = Utility.convertPostgresArrayToArray(data[0].pageMargins)
                    sheetSettings.countersColumnsRows = Utility.convertPostgresArrayToArray(data[0].countersColumnsRows)
                    sheetSettings.useCounterCustomSize = data[0].useCounterCustomSize
                    sheetSettings.printableArea = Utility.convertPostgresArrayToArray(data[0].printableArea)
                    if (data[0].sheetSide) {
                        sheetSettings.sheetSide = data[0].sheetSide
                    }
                    else {
                        sheetSettings.sheetSide = 'front'
                    }
                    if (data[0].guideType) {
                        sheetSettings.guideType = data[0].guideType
                    }
                    else {
                        sheetSettings.guideType = 'none'
                    }
                    data = sheetSettings
                }
                actions[name](data)
            }
        }
    }

    const setupDexie = () => {
        var db = new Dexie('snapcounter');
        db.version(11).stores({
            layers: 'layerKey, layerName, layerHidden, layerOpacity, inputs, layerInputKeys, layerOrder, parentLayerKey, layerActive, layerActiveRequiredInputKey',
            svgs: 'svgKey, svgName, svgCode, uniquePrepend, viewBox',
            sheetSettings: '++index, counterSize, counterCustomSize, counterMargins, countersColumnsRows, pageMargins, useCounterCustomSize, guideType, printableArea, sheetSide',
            slots: 'number, xy, centerXy, pixelsWidth, position, counterState',
            activeLayerValues: 'lik, value',
            savedCounters: 'hash, name, layers, counterSideActive, layerKeysOrdered, customImageSvgKeys, customSvgKeys, fonts, layerSvgs, dateSaved',
            savedSheets: 'hash, name, slots, sheetSettings, compressedSvg, dateSaved',
            fonts: '[fontFamily+fontStyle+fontWeight], fontSrc, fontUrl, fontUnicodeRange, fontText',
            layersOpen: 'layerKey, layerOpen',
            counterSideActive: '++index, active, front, rear',
            sheetSideActive: 'active, active, front, rear',
        })
        actions.dexie(db)
    }

    const preventReload = layerName => {
        if (state.restoreDatabase || state.importDatabase) {
            return
        }
        if (signalReloadPrevention.value.includes(layerName) === false) {
            signalReloadPrevention.value.push(layerName)
            if (window.onbeforeunload === null) {
                window.onbeforeunload = function () { return false; }
                signalDexieActive.value = signalReloadPrevention.value.join(',')
            }
        }
    }

    const releasePreventReload = layerName => {
        signalReloadPrevention.value = signalReloadPrevention.value.filter(ly => ly !== layerName)
        if (signalReloadPrevention.value.length === 0) {
            window.onbeforeunload = null
            signalDexieActive.value = false
        }
    }

    const checkOnlineStatus = async () => {
        try {
            const online = await ApiHelper.contact()
            return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
            return false; // definitely offline
        }
    };

    const loadFontsToHead = async (fonts) => {

        const loadFontsOnline = fonts => {
            let preExistingFonts = Utility.existingCustomFontIdsInDocument()
            fonts.forEach(font => {
                let fontFamily = font.fontFamily
                let link = document.createElement('link')
                link.href = font.fontUrl;
                link.rel = "stylesheet";
                link.type = "text/css";
                link.id = fontFamily.replaceAll(' ', '_') + '@' + font.fontStyle + '@' + font.fontWeight
                if (!preExistingFonts.includes(link.id)) {
                    document.head.appendChild(link);
                }
            })
        }

        const loadFontsOffline = fontsData => {
            if (!fontsData || !Array.isArray(fontsData) || fontsData.length === 0) {
                return
            }
            var head = document.getElementsByTagName('head')[0];
            var s = document.createElement('style');
            s.setAttribute('type', 'text/css');

            let styleString = '<style id="headFontsStyle" type="text/css">'
            fontsData.forEach(fontData => {
                let thisStyleString = ''
                // data put in for stand-alone mode.
                thisStyleString += "@font-face { font-family: '" + fontData.fontFamily + "'; "
                thisStyleString += 'font-weight: ' + fontData.fontWeight + '; '
                //  if (fontData.fontSrc.includes("format('woff2')") === false) {
                //      styleString += 'src: ' + fontData.fontSrc + " format('woff2');"
                //  }
                //  else {
                thisStyleString += 'src: ' + fontData.fontSrc + ';'
                //  }
                thisStyleString += '}' + "\n"
                thisStyleString = thisStyleString.replaceAll(';;', ';')
                styleString += "\n"
                styleString += thisStyleString
            })
            styleString += '</style>'
            if (s.styleSheet) {   // IE
                s.styleSheet.cssText = styleString;
            } else {                // the world
                s.appendChild(document.createTextNode(styleString));
            }
            head.appendChild(s);
        }

        if (checkOnlineStatus) {
            console.log('>>>>>>>>>>>>>>>>>>>>> online')
            loadFontsOnline(fonts)
        }
        else {
            console.log('>>>>>>>>>>>>>>>>>>>>> offline')
            loadFontsOffline(fonts)
        }

    }

    const hashedArrayToObj = (data) => {
        let obj = {}
        data.forEach(dt => {
            obj[dt.hash] = dt
        })
        return obj
    }

    const stateActiveLayerValuesToDexie = (data) => {
        if (data) {
            let dexieCompatible = []
            for (const [combinedKey, value] of Object.entries(data)) {
                dexieCompatible.push({ lik: combinedKey, value })
            }
            return dexieCompatible
        }
    }

    const stateActiveLayerValuesDexieToObj = (data) => {
        if (!data) {
            return data
        }
        if (!Array.isArray(data)) {
            return data
        }
        let obj = {}
        data.forEach(kv => {
            obj[kv.lik] = kv.value
        })

        return obj
    }

    const dragUpdate = update => {
        signalDragSubscribers.value.forEach(subscriber => {
            if (subscriber.layerKey === update.layerKey) {
                subscriber.callback.call(this, update)
            }
        })
    }

    const dragSubscribe = (subscriber) => {
        if (subscriber.add) {
            if (!signalDragSubscribers.value.find(ds => ds.layerKey === subscriber.layerKey)) {
                signalDragSubscribers.value.push(subscriber)
            }
        }
        else {
            signalDragSubscribers.value = signalDragSubscribers.value.filter(ds => ds.layerKey !== subscriber.layerKey)
        }
    }

    return startApp ?

        <div className={state.overlay ? 'home home-overlay' : 'home'}>
            <TopBar storageUsage={state.storageUsage} dexieActivity={signalDexieActive.value} />

            <div className={state.topMenuView ? 'display-none' : ''}>
                <div className={state.layoutInline ? 'app inline' : 'app'}>
                    <table className={state.layoutInline ? 'table-column' : 'table-column'}>
                        <tbody>
                            <tr className="tr-left">
                                <td className="td-cmenu">
                                    <div className="tabs" style={{ pointerEvents: state.overlay ? "none" : "" }}>
                                        <div className={tabActive === 'menu' ? 'tab active' : 'tab inactive'} onClick={() => setTabActive('menu')} >menu</div>
                                        <div className={tabActive === 'display' ? 'tab active' : 'tab inactive'} onClick={() => setTabActive('display')} >display</div>
                                    </div>
                                    <div>
                                        {tabActive === 'menu' ?
                                            <LayersMenu dragSubscribe={dragSubscribe} />
                                            :
                                            <LayersDisplay />
                                        }
                                    </div>

                                </td>
                                <td className="td-cdraw">
                                    <div className="cdraw">
                                        <CounterArea dragUpdate={dragUpdate} historyEvent={historyEvent} />
                                    </div>
                                    <DexieBusyOverlay active={signalDexieActive.value} />
                                </td>
                                {state.layoutInline ?
                                    <td className="td-sheet inline">

                                    </td>
                                    :
                                    null}
                            </tr>

                        </tbody>
                    </table >
                    <div className="sheet-container">
                        <Sheet />
                    </div>
                </div>
            </div>

            <ColorPicker />
            {state.overlay ? <Overlay /> : null}
            {state.topMenuView ? <TopMenuView page={state.topMenuView} /> : null}
            {state.popupInfoActive ? <PopupInfo /> : null}
            {state.errorMessages.length > 0 ? <ErrorMessages /> : null}

        </div >
        : null
}

export default Home