import React, { useContext, useEffect, useRef, useState } from 'react'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import DrawLayer from "../DrawLayer/DrawLayer"
import GridDisplay from "../GridDisplay/GridDisplay"
import CounterAreaCoordinatesReadout from "../CounterAreaCoordinatesReadout/CounterAreaCoordinatesReadout"
import CounterFrontRearControl from "../CounterFrontRearControl/CounterFrontRearControl"
import CounterAreaMenu from "../CounterAreaMenu/CounterAreaMenu"
import OutOfBoundsMask from "../OutOfBoundsMask/OutOfBoundsMask"
import ThreeDeeViewButton from "../ThreeDeeViewButton/ThreeDeeViewButton"
import ThreeDeeViewDraggers from "../ThreeDeeViewDraggers/ThreeDeeViewDraggers"
import PinControl from "../PinControl/PinControl"
import HistoryControl from "../HistoryControl/HistoryControl"
import './CounterArea.scss'

const CounterArea = ({ dragUpdate, historyEvent }) => {
    const { state, actions } = useContext(StoreContext)
    const signalGridState = useSignal(false)
    const signalGridColor = useSignal('light')
    const signalOrigTop = useSignal(0)
    const signalMenuHeight = useSignal(0)
    const signalSvgReferences = useSignal([])
    const sgUtime = useSignal(null)
    const counterDrawingAreaRef = useRef(null)
    const [threeDeeActive, setThreeDeeActive] = useState(false)
    const [threeDeeValues, setThreeDeeValues] = useState(null)
    const [pinned, setPinned] = useState(false)
    const [flipping, setFlipping] = useState(null)
    const [counterDrawn, setCounterDrawn] = useState(null)
    const [drawnTimer, setDrawnTimer] = useState(null)

    useEffect(() => {
        computeInitialCoords()
    }, [])


    useEffect(() => {
        if (state.counterLoadFromApp) {
            let loadingCounterSideActive = state.counterLoadFromApp
            loadingCounterSideActive.active = state.counterSideActive.active // use side counter area is set to
            loadingCounterSideActive.front = state.counterLoadFromApp.counterSideActive.front
            loadingCounterSideActive.rear = state.counterLoadFromApp.counterSideActive.rear
            actions.counterSideActive(loadingCounterSideActive)
            let alv = loadingCounterSideActive.active === 'front' ? loadingCounterSideActive.front : loadingCounterSideActive.rear
            actions.activeLayerValuesReset(JSON.parse(alv))
        }
    }, [state.counterLoadFromApp])

    useEffect(() => {
        if (state.counterLoadFromSheet) {
            let loadingCounterSideActive = state.counterSideActive
            loadingCounterSideActive.active = state.counterSideActive.active // use side counter area is set to
            loadingCounterSideActive.front = state.counterLoadFromSheet.counterSideActive.front
            loadingCounterSideActive.rear = state.counterLoadFromSheet.counterSideActive.rear
            actions.counterSideActive(loadingCounterSideActive)
            let alv = loadingCounterSideActive.active === 'front' ? loadingCounterSideActive.front : loadingCounterSideActive.rear
            actions.activeLayerValuesReset(JSON.parse(alv))
        }
    }, [state.counterLoadFromSheet])

    useEffect(() => {
        // when loading from file we need to see if there are new svgs, new fonts, new layers coming in.
        if (state.counterLoadFromFile) {
            if (state.counterLoadFromFile.version === 2) {
                loadCounterV2(state.counterLoadFromFile)
            }
            if (state.counterLoadFromFile.version === 3) {
                loadCounterV3(state.counterLoadFromFile, 'file')
            }
        }
    }, [state.counterLoadFromFile])



    const loadCounterV2 = data => {
        /*
        data.layers
        data.svgs
        data.counterSideActive
        data.fonts
        */
        //console.log('data:', data)
        let incomingActiveLayers = data.layers
        let newStateLayers = JSON.parse(JSON.stringify(state.layers))
        //console.log('newStateLayers:', newStateLayers)
        // include any new (duped) layers
        incomingActiveLayers.forEach(ily => {
            let found = newStateLayers.find(sly => sly.layerKey === ily.layerKey)
            if (!found) {
                newStateLayers.push(ily)
            }
        })

        incomingActiveLayers.forEach(ily => {
            let matchedNewStateLayer = newStateLayers.find(nsl => nsl.layerKey === ily.layerKey)
            if (matchedNewStateLayer) {
                if (ily.layerHidden === 0) {
                    matchedNewStateLayer.layerHidden = 0
                }
            }
        })

        actions.layers(newStateLayers)
        actions.counterSideActive(data.counterSideActive)
        let alv = JSON.parse(data.counterSideActive.front)
        actions.activeLayerValuesReset(alv)
    }

    const loadCounterV3 = data => {
        actions.fonts(data.fonts)
        actions.layers(data.layers)
        // svgs were loaded to state ln loadCounterControl.js
        let loadingCounterSideActive = data.counterSideActive
        loadingCounterSideActive.active = data.counterSideActive.active // use side counter area is set to
        loadingCounterSideActive.front = data.counterSideActive.front
        loadingCounterSideActive.rear = data.counterSideActive.rear
        actions.counterSideActive(loadingCounterSideActive)
        let alv = loadingCounterSideActive.active === 'front' ? loadingCounterSideActive.front : loadingCounterSideActive.rear
        actions.activeLayerValuesReset(JSON.parse(alv))
    }


    // will handle customImageSvgsData, customSvgsData, fontsData
    const loadCounterMoved = data => {
        if (data) {
            //////////////////////////////////////////////////////////
            // check fontsData
            //////////////////////////////////////////////////////////
            if (data.fontsData && data.fontsData.length > 0) {
                let installFonts = []
                data.fontsData.forEach(fd => {
                    if (!state.fonts.find(sf => sf.fontFamily === fd.fontFamily)) {
                        installFonts.push(fd)
                    }
                })

                if (installFonts.length > 0) {
                    let fonts = [...state.fonts, ...installFonts]
                    actions.fonts(fonts)
                }
            }

            let stateLayersCopy = [...state.layers]
            if (data.version === 2) {
                stateLayersCopy = data.layers

                state.layers.forEach(sly => {
                    let found = stateLayersCopy.find(cp => cp.layerKey === sly.layerKey)
                    if (!found) {
                        stateLayersCopy.push(sly)
                    }
                })
            }

            //////////////////////////////////////////////////////////
            // duplicate layers
            //////////////////////////////////////////////////////////

            let stateLayersByType = []
            let incomingLayersByType = []
            stateLayersCopy.forEach(sl => {
                let layerType = ''
                if (sl.parentLayerKey === -1) {
                    layerType = sl.layerName
                }
                else {
                    layerType = Utility.originalLayerName(stateLayersCopy, sl)
                }
                stateLayersByType.push({ layerType, layerKey: sl.layerKey, parentLayerKey: sl.parentLayerKey })
            })
            stateLayersByType = stateLayersByType.sort((a, b) => a.layerKey - b.layerKey)
            data.layers.forEach(sl => {
                let layerType = ''
                if (sl.parentLayerKey === -1) {
                    layerType = sl.layerName
                }
                else {
                    layerType = Utility.originalLayerName(stateLayersCopy, sl)
                }
                incomingLayersByType.push({ layerType, layerKey: sl.layerKey, parentLayerKey: sl.parentLayerKey })
            })
            let incomingDupeLayersWeNeedToDealWith = []
            let removeMatched = []
            incomingLayersByType.forEach(ilbt => {
                if (stateLayersByType.find(slbt => slbt.layerKey === ilbt.layerKey && slbt.parentLayerKey === ilbt.parentLayerKey)) {
                    removeMatched.push(ilbt.layerKey)
                }
            })
            stateLayersByType = stateLayersByType.filter(slbt => removeMatched.includes(slbt.layerKey) === false)
            incomingLayersByType = incomingLayersByType.filter(ilbt => removeMatched.includes(ilbt.layerKey) === false)
            // now if we have anything left in incomingLayersByType, see if they match up with whats left in state.
            let changeIncomingLayerKeysFromTo = []
            incomingLayersByType.forEach(ilbt => {
                let foundPlace = stateLayersByType.find(slbt => slbt.layerType === ilbt.layerType)
                if (foundPlace) {
                    changeIncomingLayerKeysFromTo.push({ from: ilbt.layerKey, to: foundPlace.layerKey })
                    stateLayersByType = stateLayersByType.filter(slbt => slbt.layerKey !== foundPlace.layerKey)
                }
            })

            if (changeIncomingLayerKeysFromTo.length > 0) {
                changeIncomingLayerKeysFromTo.forEach(cilkft => {
                    let from = cilkft.from
                    let to = cilkft.to
                    // update the alv in the incoming counter
                    let front = data.counterSideActive.front
                    let rear = data.counterSideActive.rear
                    data.counterSideActive.front = front.replaceAll('"' + from + '_', '"' + to + '_')
                    data.counterSideActive.rear = rear.replaceAll('"' + from + '_', '"' + to + '_')
                    // change the incoming layers too
                    let foundIncomingLayer = data.layers.find(il => il.layerKey === from)
                    if (foundIncomingLayer) {
                        foundIncomingLayer.layerKey = to
                    }
                })
            }

            incomingLayersByType = incomingLayersByType.filter(ilbt => !changeIncomingLayerKeysFromTo.find(change => change.from === ilbt.layerKey))
            // if we have any left, we need to make new duplicated layer(s).
            if (incomingLayersByType.length > 0) {
                let createDupeLayersFor = data.layers.filter(clff => incomingLayersByType.find(ilbt => ilbt.layerKey === clff.layerKey))
                createDupeLayersFor.forEach(cdl => {
                    let duplicatedResult = createDuplicateLayer(stateLayersCopy, cdl)
                    // update the alv in the incoming counter
                    let front = data.counterSideActive.front
                    let rear = data.counterSideActive.rear
                    data.counterSideActive.front = front.replaceAll('"' + cdl.layerKey + '_', '"' + duplicatedResult.newLayerKey + '_')
                    data.counterSideActive.rear = rear.replaceAll('"' + cdl.layerKey + '_', '"' + duplicatedResult.newLayerKey + '_')
                    stateLayersCopy = duplicatedResult.updatedStateLayers
                })
                actions.layers([...stateLayersCopy])
            }


            ///////////////////////////////////////////////////stateNotCustomLayers
            // incoming custom images/svgs
            ///////////////////////////////////////////////////
            let incomingImageSvgsData = data.customImageSvgsData
            let incomingSvgsData = data.customSvgsData
            let changeCustomSvgKeysFromTo = []
            let installSvgs = []
            incomingImageSvgsData.forEach(iisvg => {
                if (!state.svgs.find(ss => ss.svgKey === iisvg.svgKey && ss.svgKey === iisvg.svgCode)) {
                    // see if its just a svgKey change
                    let found = state.svgs.find(ss => ss.svgCode === iisvg.svgCode)
                    if (found) {
                        changeCustomSvgKeysFromTo.push({ from: iisvg.svgKey, to: found.svgKey })
                    }
                    else {
                        installSvgs.push({ svg: iisvg, type: "image" })
                    }
                }
            })
            incomingSvgsData.forEach(iisvg => {
                if (!state.svgs.find(ss => ss.svgKey === iisvg.svgKey && ss.svgCode === iisvg.svgCode)) {
                    // see if its just a svgKey change
                    let found = state.svgs.find(ss => ss.svgCode === iisvg.svgCode)
                    if (found) {
                        changeCustomSvgKeysFromTo.push({ from: iisvg.svgKey, to: found.svgKey })
                    }
                    else {
                        installSvgs.push({ svg: iisvg, type: "svg" })
                    }
                }
            })
            if (installSvgs.length > 0) {

                // get the list of custom image and svg layers in state.
                let stateCustomImageLayers = []
                let stateCustomSvgLayers = []
                let stateNotCustomLayers = []
                stateLayersCopy.forEach(sl => {
                    let pulled = false
                    let layerType = ''
                    if (sl.layerName === 'custom images') {
                        stateCustomImageLayers.push({ ...sl })
                        pulled = true
                    }
                    if (sl.layerName === 'custom svgs') {
                        stateCustomSvgLayers.push({ ...sl })
                        pulled = true
                    }
                    if (sl.parentLayerKey > -1) {
                        layerType = Utility.originalLayerName(stateLayersCopy, sl)
                        if (layerType === 'custom images') {
                            stateCustomImageLayers.push({ ...sl })
                            pulled = true
                        }
                        if (layerType === 'custom svgs') {
                            stateCustomSvgLayers.push({ ...sl })
                            pulled = true
                        }
                    }
                    if (pulled === false) {
                        stateNotCustomLayers.push(sl)
                    }
                })

                let newSvgs = []
                let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey
                for (let i = 0; i < installSvgs.length; i++) {
                    let svgType = installSvgs[i]
                    let svg = svgType.svg
                    let type = svgType.type
                    // get next svgKey
                    if (useKey < 2000) {
                        useKey = 2000
                    }
                    else {
                        useKey++
                    }
                    let fromKey = svg.svgKey
                    let toKey = useKey
                    svg.svgKey = toKey
                    newSvgs.push(svg)
                    changeCustomSvgKeysFromTo.push({ from: fromKey, to: toKey })
                    // add new svg to the proper svg list of the custom layer and make it unhidden if currently hidden.
                    //let stateLayers = [...state.layers]
                    if (type === 'image') {
                        stateCustomImageLayers.forEach(ly => {
                            let input = ly.inputs.find(li => li.named === 'svgKey')
                            if (input) {
                                if (input.list && Array.isArray(input.list)) {
                                    ly.layerHidden = 0
                                    if (input.list.includes(toKey) === false) {
                                        input.list.push(toKey)
                                    }
                                }
                            }
                        })
                    }
                    if (type === 'svg') {
                        stateCustomSvgLayers.forEach(ly => {
                            let input = ly.inputs.find(li => li.named === 'svgKey')
                            if (input) {
                                if (input.list && Array.isArray(input.list)) {
                                    ly.layerHidden = 0
                                    if (input.list.includes(toKey) === false) {
                                        input.list.push(toKey)
                                    }
                                }
                            }
                        })
                    }
                }
                if (newSvgs.length > 0) {
                    actions.svgs([...state.svgs, ...newSvgs])
                    actions.layers([...stateLayersCopy, ...stateCustomImageLayers, ...stateCustomSvgLayers])
                }
            }

            if (changeCustomSvgKeysFromTo.length > 0) {
                let incomingCustomLayers = []
                data.layers.forEach(il => {
                    if (il.layerName === 'custom images' || il.layerName === 'custom svgs') {
                        incomingCustomLayers.push(il)
                    }
                    else {
                        if (il.parentLayerKey > -1) {
                            let layerType = Utility.originalLayerName(state.layers, il)
                            if (layerType === 'custom images' || layerType === 'custom svgs') {
                                incomingCustomLayers.push(il)
                            }
                        }
                    }
                })
                let alvFront = JSON.parse(data.counterSideActive.front)
                let alvRear = JSON.parse(data.counterSideActive.rear)
                changeCustomSvgKeysFromTo.forEach(fromTo => {
                    incomingCustomLayers.forEach(ly => {
                        let svgInputKey = ly.layerActiveRequiredInputKey
                        let currentSvgKeyFront = alvFront[ly.layerKey + '_' + svgInputKey]
                        let currentSvgKeyRear = alvRear[ly.layerKey + '_' + svgInputKey]
                        // front
                        let foundChange = changeCustomSvgKeysFromTo.find(ch => ch.fromSvgKey === currentSvgKeyFront)
                        if (foundChange) {
                            alvFront[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                        }
                        // rear
                        foundChange = changeCustomSvgKeysFromTo.find(ch => ch.fromSvgKey === currentSvgKeyRear)
                        if (foundChange) {
                            alvRear[ly.layerKey + '_' + svgInputKey] = foundChange.toSvgKey
                        }
                    })
                })
                data.counterSideActive.front = JSON.stringify(alvFront)
                data.counterSideActive.rear = JSON.stringify(alvRear)
            }
            let alv = data.counterSideActive.active === 'front' ? data.counterSideActive.front : data.counterSideActive.rear
            actions.counterSideActive(data.counterSideActive)
            actions.activeLayerValuesReset(JSON.parse(alv))
        }
    }

    const createDuplicateLayer = (newLayers, dupeThisLayer) => {
        let updatedStateLayers = [...newLayers]
        let duplicateLayer = { ...dupeThisLayer }
        // get the parent layer we are duping from.
        let parentLayer = updatedStateLayers.find(sl => sl.layerKey === duplicateLayer.parentLayerKey)
        if (parentLayer) {
            duplicateLayer.layerOrder = parentLayer.layerOrder + 0.5
            let maxLayerKey = Math.max(...updatedStateLayers.map(ly => parseInt(ly.layerKey)), 0)
            duplicateLayer.layerKey = maxLayerKey + 1
            duplicateLayer.layerHidden = 0
            duplicateLayer.layerActive = 1
            // check name to avoid dupe names
            let incNum = 2
            let dupeName = duplicateLayer.layerName
            while (newLayers.find(nl => nl.layerName === dupeName)) {
                dupeName = duplicateLayer.layerName + incNum
                incNum++
                if (incNum > 100) {
                    break
                }
            }
            duplicateLayer.layerName = dupeName
            // fix up orderings, since we're jamming in a new layer inbetween two (unless its at the end)
            updatedStateLayers.push(duplicateLayer)
            updatedStateLayers.sort((a, b) => a.layerOrder - b.layerOrder)
            updatedStateLayers.forEach((ly, index) => {
                ly.layerOrder = index
            })

        }
        return { updatedStateLayers: updatedStateLayers, newLayerKey: duplicateLayer.layerKey }
    }

    useEffect(() => {
        computeInitialCoords()
    }, [state.windowWidthHeight])

    const computeInitialCoords = () => {
        let bbox = null
        const counterEl = document.getElementById('counter')
        if (counterEl) {
            bbox = counterEl.getBoundingClientRect()
            signalOrigTop.value = bbox.top
        }

        const counterMenuEl = document.getElementsByClassName('counter-area-menu')[0]
        if (counterMenuEl) {
            bbox = counterMenuEl.getBoundingClientRect()
            signalMenuHeight.value = bbox.height
        }
    }

    const showGrid = onOff => {
        signalGridState.value = onOff
    }

    const gridColor = lightDark => {
        signalGridColor.value = lightDark
    }

    const clearCounter = () => {
        let clearedActiveLayerValues = { ...state.activeLayerValues }
        for (const [key] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith('1_') === false) {
                delete clearedActiveLayerValues[key]
            }
        }
        actions.activeLayerValuesReset(clearedActiveLayerValues)
        actions.counterClear(true)
    }

    useEffect(() => {
        if (pinned) {
            return
        }
        const counterEl = document.getElementById('counter')
        let counterElBox = counterEl.getBoundingClientRect()
        let trLeft = document.getElementsByClassName('tr-left')[0]
        let trLeftBox = trLeft.getBoundingClientRect()

        let topSetting = window.scrollY  //state.scrollTop
        if (window.scrollY + counterElBox.height > trLeftBox.height) {
            let byHowFar = trLeftBox.height - (window.scrollY + counterElBox.height) - 43
            topSetting += byHowFar
        }
        if (topSetting < 0) {
            topSetting = 0
        }

        counterEl.style.top = topSetting + 'px';

    }, [state.scrollTop, state.windowResize, pinned])

    const mouseOutHandler = e => {
        actions.mouseOutCounterArea(true)
    }

    const mouseInHandler = e => {
        actions.mouseOutCounterArea(false)
    }

    useEffect(() => {
        if (state.moveCounterToSheet) {
            if (counterDrawingAreaRef.current) {
                counterDrawingAreaRef.current.className = state.layoutInline ? "drawing-area move-to-sheet-right" : "drawing-area move-to-sheet-down"
                setTimeout(() => {
                    counterDrawingAreaRef.current.className = "drawing-area";
                }, 1000)
            }
        }
    }, [state.moveCounterToSheet])

    const onChangeThreeDeeActive = active => {
        setThreeDeeActive(active)
    }

    const onChangeThreeDeeValues = values => {
        setThreeDeeValues(values)
    }

    useEffect(() => {
        if (threeDeeActive) {
            distribute3dTransforms(threeDeeValues)
        }
        else {
            distribute3dTransforms(false)
        }
    }, [threeDeeActive, threeDeeValues])

    const distribute3dTransforms = threeDeeValues => {
        let layers = Utility.activeLayers(state.activeLayerValues, state.layers)
        let drawnLayerElements = layers.map(ly => document.getElementById('drawLayer_' + ly.layerKey))
        drawnLayerElements = drawnLayerElements.filter(dleid => dleid)
        if (drawnLayerElements.length === 0) {
            return
        }

        if (threeDeeValues) {

            let middle = drawnLayerElements.length / 2

            let { explodeDistance, viewAngle, viewRotation } = threeDeeValues
            let perspective = 20000
            let scaleX = 0.9
            let scaleY = (viewAngle * 0.0125) // view angle
            let rotation = viewRotation + 'deg'
            let ln = Math.log(100 - viewAngle)
            perspective -= ln * 3400
            if (!perspective || perspective >= 20000) {
                perspective = 130000
            }
            drawnLayerElements.forEach((ele, index) => {
                let num = index + 1

                let position = (num - 1) - middle
                position += 0.5
                let breakup100by = 100 / drawnLayerElements.length
                let reversedViewAngle = (101 - viewAngle) / 100
                let distanceToElevate = (position * breakup100by) / (100 / explodeDistance)
                if (explodeDistance === 0) {
                    distanceToElevate = 0
                }
                let heightAdjust = -distanceToElevate
                heightAdjust *= reversedViewAngle
                if (reversedViewAngle === 0.01) {
                    heightAdjust = 0
                }
                ele.style.transform = `
                        translateY(${heightAdjust}%)
                        scaleY(${scaleY})
                        rotateY(${rotation})
                        scaleX(${scaleX})
                        perspective(${perspective}px) 
                        rotateX(45deg)
                        `;
            })

        }
        else {
            drawnLayerElements.forEach(ele => {
                ele.style.transform = 'none'
            })
        }
    }

    const newShade = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);
        if (hexColor.length === 6) {
            const decimalColor = parseInt(hexColor, 16);
            let r = (decimalColor >> 16) + magnitude;
            r > 255 && (r = 255);
            r < 0 && (r = 0);
            let g = (decimalColor & 0x0000ff) + magnitude;
            g > 255 && (g = 255);
            g < 0 && (g = 0);
            let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
            b > 255 && (b = 255);
            b < 0 && (b = 0);
            return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
        } else {
            return hexColor;
        }
    };

    // all this does is return the current front and rear values, except if the rear is blank, we take the
    // current background color and make a lighter version of the color, as an indicator to the user that
    // something happened (like the counter was flipped to a weaker side).
    const manageCounterFrontRearValues = side => {
        let frontValues = state.counterSideActive.front
        let rearValues = state.counterSideActive.rear

        // if we are changing from front to rear view
        // if (side === 'rear') {
        // if rear values are blank, create a blank version of the front (i.e. same background)
        if (Utility.emptyCheck(JSON.parse(rearValues))) {
            // create rear side of counter with just the background color.
            let justBackground = {}
            for (const [key, value] of Object.entries(state.activeLayerValues)) {
                if (key.startsWith('1_')) {
                    justBackground[key] = value // copy in current values
                }
            }
            let colorValue = JSON.parse(justBackground['1_181'])
            if (colorValue.fillType === 'solid') {
                let fillColor = colorValue.fillColor
                let newColor = newShade(fillColor, 80) // lighter
                colorValue.fillColor = newColor
                justBackground['1_181'] = JSON.stringify(colorValue)
                justBackground['1_35'] = 0 // square
            }

            // put this into rear values
            rearValues = JSON.stringify(justBackground) // will overwrite whatever may had been there.
            // and since we are making the rear view the visible side
        }

        return { front: frontValues, rear: rearValues }
    }

    useEffect(() => {
        if (flipping) {
            let newAlv = state.counterSideActive.active === 'front' ? state.counterSideActive.front : state.counterSideActive.rear
            actions.activeLayerValuesReset(JSON.parse(newAlv))
            setFlipping(false)
        }
    }, [state.counterSideActive])

    // side here means side we are going to.
    const flipCounter = side => {

        createCloneCounter(side) // paints a svg copy over the counter, so we dont see the activeLayerValues changing.
        let { front, rear } = manageCounterFrontRearValues(side)

        setFlipping(true)
        // // //when switching to the other side, we want to copy in the current activeLayerValues into the side being switched away from.
        actions.counterSideActive({ active: side, front, rear })
        setTimeout(() => {
            setTimeout(() => {
                doFlipAnimation(side)
            }, 100)

            setTimeout(() => {
                // remove the svg combined dupe
                let ele = document.getElementById('svgCopy')
                ele.remove()
            }, 400)
        }, 300)
    }

    const copyFrom = fromSide => {
        let toSide = fromSide === 'front' ? 'rear' : 'front'
        let fromSideAlvString = state.counterSideActive[fromSide]
        let fromSideAlvObj = JSON.parse(fromSideAlvString)
        if (toSide === 'front') {
            //actions.counterSideActive({ ...state.counterSideActive, front: fromSideAlvString })
        }
        else {
            //actions.counterSideActive({ ...state.counterSideActive, rear: fromSideAlvString })
        }
        actions.activeLayerValuesReset(fromSideAlvObj)
    }

    const createCloneCounter = () => {
        let counterSvg = combineSvgsOnCounter()
        createCloneSVG(counterSvg)
    }

    const combineSvgsOnCounter = () => {
        let builtUpSvg = '<svg id="combined" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">'
        // get the active layers 
        let layersOrder = state.layers.map(ly => ly.layerActive ? { layerKey: ly.layerKey, layerOrder: ly.layerOrder } : null)
        layersOrder = layersOrder.filter(lo => lo)
        layersOrder = layersOrder.sort((a, b) => a.layerOrder - b.layerOrder)
        layersOrder.forEach(alk => {
            let id = 'drawLayer_' + alk.layerKey
            builtUpSvg += document.getElementById(id).innerHTML + "\n\n"
        })
        builtUpSvg += '</svg>'

        return builtUpSvg
    }

    const createCloneSVG = (svg) => {
        var xmlns = "http://www.w3.org/2000/svg";
        var svgElem = document.createElementNS(xmlns, "svg");
        svgElem.setAttributeNS(null, "viewBox", "0, 0, 240, 240");
        svgElem.setAttributeNS(null, "width", "100%");
        svgElem.setAttributeNS(null, "height", "100%");
        svgElem.setAttributeNS(null, "id", "svgCopy")
        svgElem.setAttributeNS(null, "class", "draw-layer svg-copy")
        var svgContainer = document.getElementById("drawingArea");
        svgContainer.append(svgElem)
        let _paper = Snap('#svgCopy');
        let parsed = Snap.parse(svg)
        _paper.append(parsed)
        svgElem.style.position = "absolute";
        svgElem.style.top = "0px";
        svgElem.style.left = "0px";
        svgElem.style.opacity = 1;
        svgElem.style.zIndex = 1000;
    }

    const doFlipAnimation = (direction) => {
        var list = document.getElementsByClassName("draw-layer");
        for (var i = 0; i < list.length; i++) {
            list[i].classList.add(`flip-${direction}`);
        }
        setTimeout(() => {
            for (var i = 0; i < list.length; i++) {
                list[i].classList.remove(`flip-${direction}`);
            }
        }, 600)
    }

    const svgReference = svgRef => {
        let layerKey = svgRef.layerKey
        //signalSvgReferences.value.filter(sr => sr.layerKey !== layerKey)
        let found = signalSvgReferences.value.find(sr => sr.layerKey === layerKey)
        if (!found) {
            signalSvgReferences.value.push(svgRef)
        }
        let activeLayers = state.layers.filter(sl => sl.layerActive === 1)

        let utime = new Date().getTime()
        //throttleUpdate(setCounterDrawn, utime, 10000)
        sgUtime.v = utime
        let tid = setTimeout(() => {
            setCounterDrawn(sgUtime.v)
            setDrawnTimer(null)
        }, 100)
        if (drawnTimer) {
            clearTimeout(drawnTimer)
        }
        setDrawnTimer(tid)
    }

    useEffect(() => {
        let utime = new Date().getTime()
        sgUtime.v = utime
        let tid = setTimeout(() => {
            setCounterDrawn(sgUtime.v)
            setDrawnTimer(null)
        }, 100)
        if (drawnTimer) {
            clearTimeout(drawnTimer)
        }
        setDrawnTimer(tid)
    }, [state.layers])

    useEffect(() => {
        actions.counterRedrawn(counterDrawn)
    }, [counterDrawn])

    const changePinned = pinned => {
        setPinned(pinned)
    }

    return (
        <div className="counter-area" id="counter">
            <CounterAreaMenu showGrid={showGrid}
                gridColor={gridColor}
                clearCounter={clearCounter}
                gridState={signalGridState.value} />
            <div ref={counterDrawingAreaRef} id="drawingArea" className={threeDeeActive ? 'drawing-area three-dee' : 'drawing-area'} onMouseOver={mouseInHandler} onMouseOut={mouseOutHandler}>
                {state.layers.map((layer, index) => {
                    if (layer.layerActive === 1) {
                        return <DrawLayer key={index} layer={layer} dragUpdate={dragUpdate} svgReference={svgReference} />
                    }
                })}

                <OutOfBoundsMask />
                <PinControl changePinned={changePinned} hide={threeDeeActive} />
                <GridDisplay showingGrid={signalGridState.value} usingGridColor={signalGridColor.value} threeDeeActive={threeDeeActive} />
                <CounterFrontRearControl flipCounter={flipCounter} copyFrom={copyFrom} hide={threeDeeActive} />
                <CounterAreaCoordinatesReadout hide={threeDeeActive} />
                <ThreeDeeViewButton reportThreeDeeActive={onChangeThreeDeeActive} />
                <ThreeDeeViewDraggers threeDeeActive={threeDeeActive} reportThreeDeeValues={onChangeThreeDeeValues} />
                <HistoryControl historyEvent={historyEvent} hide={threeDeeActive} />

            </div>

        </div>
    )
}

export default CounterArea