import React, { useRef, useContext, useState, useEffect } from 'react'
import { useSignal } from "@preact/signals-react"
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import SheetControls from '../SheetControls/SheetControls'
import SheetOperations from '../SheetOperations/SheetOperations'
import './Sheet.scss'

const Sheet = ({ layer, setOverlayActive }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const { state, actions } = useContext(StoreContext)
    const svgRef = useRef(null)
    const [printableAreaMM, setPrintableAreaMM] = useState([207.44, 270.94])
    //  8.46457 inches 11 inches
    const sheetDisplayRef = useRef(null)
    const [sheetParameters, setSheetParameters] = useState(null)
    const [sheetMenuControlsOpened, setSheetMenuControlsOpened] = useState(false)
    const [sheetMenuOperationsOpened, setSheetMenuOperationsOpened] = useState(false)
    const [sheetSide, setSheetSide] = useState('front')

    const sgPreviousWindowResize = useSignal({ width: -1, height: -1 })

    const sgContextMenuActive = useSignal(null)
    const sgLastSheetParameters = useSignal({})
    const [contextBox, setContextBox] = useState({ top: -1, left: -1 })
    const sgPaintHash = useSignal(null)
    const draggingSlot = useSignal(null)

    const [sheetPaper, setSheetPaper] = useState(null)
    const [changingGuideType, setChangingGuideType] = useState(false)
    const [sheetAspectRatioChangedSheetParameters, setSheetAspectRatioChangedSheetParameters] = useState(false)
    const [redoSheet, setRedoSheet] = useState(false)

    useEffect(() => {
        let _paper = setupSheetPaper()
        actions.sheetPaper(_paper)
        setSheetPaper(_paper)

        const mouseUp = evt => {
            if (evt.target.id.startsWith('sheet_context_menu') === false && sgContextMenuActive.value) {
                let slotNumber = sgContextMenuActive.value.number
                setTimeout(() => {
                    if (sgContextMenuActive.value && sgContextMenuActive.value.number === slotNumber) {
                        sgContextMenuActive.value = null
                    }
                }, 0)
            }
        }
        document.getElementById('sheetContainer').addEventListener("mouseup", mouseUp, { signal });

        // }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setupSheetPaper = () => {
        let _paper = Snap("#sheetDisplay");
        _paper.attr({ id: "sheetDisplay", width: "100%", height: "100%" });
        return _paper
    }

    useEffect(() => {
        if (state.sheetPaper) {
            if (state.sheetSettings && !Utility.emptyCheck(state.sheetSettings)) {
                if (!sheetParameters) {
                    setupSheetParameters()
                }
            }

        }
    }, [state.sheetPaper])

    const setupSheetParameters = () => {
        let translatedSheetParameters = null
        let useCounterSizeMM = 12.7
        if (!state.sheetSettings.counterSize || state.sheetSettings.counterSize === -1) {
            useCounterSizeMM = 12.7
        }
        else {
            useCounterSizeMM = state.sheetSettings.counterSize
        }
        let useCounterCustomSize = false
        if (state.sheetSettings.useCounterCustomSize && state.sheetSettings.counterCustomSize) {
            useCounterSizeMM = state.sheetSettings.counterCustomSize
            useCounterCustomSize = true
        }

        let usePrintableAreaMM = printableAreaMM // default
        if (state.sheetSettings.printableArea) {
            usePrintableAreaMM = state.sheetSettings.printableArea
        }

        let sheetSizes = constructSheetSizes(useCounterSizeMM, usePrintableAreaMM)
        // sheetSizes domWidth, domHeight, ratioMMtoPixels, counterSizeInPixels, countersColumnsRows
        let sheetSide = 'front'
        if( state.sheetSettings.sheetSide ) {
            sheetSide = state.sheetSettings.sheetSide
        }

        let countersLayoutMaxColsRows = computeCountersMaxColsRows(sheetSizes, state.sheetSettings.counterMargins, state.sheetSettings.pageMargins)
        let maxCols = countersLayoutMaxColsRows.cols // defaults to max cols and rows that can fit
        let maxRows = countersLayoutMaxColsRows.rows

        translatedSheetParameters = {
            cols: state.sheetSettings.countersColumnsRows[0],
            rows: state.sheetSettings.countersColumnsRows[1],
            counterSizeInPixels: useCounterSizeMM,
            counterCustomSize: useCounterCustomSize ? useCounterSizeMM : '',
            useCounterCustomSize: useCounterCustomSize,
            counterSizeMM: state.sheetSettings.counterSize,
            width: sheetSizes.domWidth,
            height: sheetSizes.domHeight,
            printableAreaMM: usePrintableAreaMM,
            maxCols: maxCols,
            maxRows: maxRows,
            ratioMMtoPixels: sheetSizes.ratioMMtoPixels,
            counterMargins: state.sheetSettings.counterMargins,
            pageMargins: state.sheetSettings.pageMargins,
            guideType: state.sheetSettings.guideType,
            sheetSide: sheetSide,
        }
        let _sheetParameters = computeSheetParameters(translatedSheetParameters)
        setSheetParameters(_sheetParameters)
    }

    const computeSheetParameters = (useSheetParameters = sheetParameters) => {
        let counterSizeMM = 12.7 // default 
        let counterMargins = [0, 0, 0, 0] // default
        let pageMargins = [0, 0, 0, 0] // default
        let counterCustomSize = '' // default
        let useCounterCustomSize = false // default
        let useGuideType = 'none' // default
        let usePrintableAreaMM = printableAreaMM
        let sheetSide = 'front'

        if (useSheetParameters) {
            if (useSheetParameters.guideType) {
                useGuideType = useSheetParameters.guideType
            }
            if (useSheetParameters.sheetSide) {
                sheetSide = useSheetParameters.sheetSide   
            }
            if (useSheetParameters.counterSizeMM) {
                counterSizeMM = useSheetParameters.counterSizeMM
            }
            if (useSheetParameters.counterCustomSize && useSheetParameters.useCounterCustomSize) {
                counterSizeMM = useSheetParameters.counterCustomSize
                counterCustomSize = useSheetParameters.counterCustomSize
                useCounterCustomSize = useSheetParameters.useCounterCustomSize
            }

            if (useSheetParameters.counterMargins) {
                counterMargins = useSheetParameters.counterMargins
            }
            if (useSheetParameters.pageMargins) {
                pageMargins = useSheetParameters.pageMargins
            }
            if (useSheetParameters.printableAreaMM) {
                usePrintableAreaMM = useSheetParameters.printableAreaMM
            }
        }
        let sheetSizes = constructSheetSizes(counterSizeMM, usePrintableAreaMM)
        let countersLayoutMaxColsRows = computeCountersMaxColsRows(sheetSizes, counterMargins, pageMargins)

        let useCols = countersLayoutMaxColsRows.cols // defaults to max cols and rows that can fit
        let useRows = countersLayoutMaxColsRows.rows
        // use existing cols and rows settings, unless they dont fit
        if (useSheetParameters && useSheetParameters.cols && useSheetParameters.rows) {
            if (useSheetParameters.cols < useCols) {
                useCols = useSheetParameters.cols
            }
            if (useSheetParameters.rows < useRows) {
                useRows = useSheetParameters.rows
            }
        }
        return {
            width: sheetSizes.domWidth,
            height: sheetSizes.domHeight,
            printableAreaMM: usePrintableAreaMM,
            counterSizeInPixels: sheetSizes.counterSizeInPixels,
            counterSizeMM: sheetSizes.counterSizeMM,
            counterCustomSize: counterCustomSize,
            useCounterCustomSize: useCounterCustomSize,
            ratioMMtoPixels: sheetSizes.ratioMMtoPixels,
            cols: useCols,
            rows: useRows,
            maxCols: countersLayoutMaxColsRows.cols,
            maxRows: countersLayoutMaxColsRows.rows,
            counterMargins: counterMargins,
            pageMargins: pageMargins,
            ratioMMtoPixels: sheetSizes.ratioMMtoPixels,
            guideType: useGuideType,
            sheetSide: sheetSide,
        }
    }

    useEffect(() => {
        if (sheetParameters) {
            if (changingGuideType) {
                actions.sheetSettings({ ...state.sheetSettings, guideType: changingGuideType })
                return
            }
            sgContextMenuActive.value = null
            let slots = generateSlotsData()
            // preserve counterState data for any slots with counters in them.
            if (state.slots.length > 0) {
                slots.forEach(slot => {
                    let sslot = state.slots.find(ss => ss.number === slot.number)
                    if (sslot) {
                        if (sslot.counterState && !Utility.emptyCheck(sslot.counterState)) {
                            slot.counterState = { ...sslot.counterState }
                        }
                    }
                })
            }
            actions.slots(slots)
            let _sheetSettings = {
                counterCustomSize: sheetParameters.counterSizeMM,
                counterMargins: sheetParameters.counterMargins,
                pageMargins: sheetParameters.pageMargins,
                counterSize: sheetParameters.counterSizeMM,
                useCounterCustomSize: sheetParameters.useCounterCustomSize,
                countersColumnsRows: [sheetParameters.cols, sheetParameters.rows],
                printableArea: sheetParameters.printableAreaMM,
                guideType: sheetParameters.guideType,
                sheetSide: sheetParameters.sheetSide,
            }
            actions.sheetSettings(_sheetSettings)
        }
    }, [sheetParameters])

    const generateSlotsData = () => {
        let obj = {}
        let slots = []
        // 'number', 'xy', 'centerXy', 'pixelsWidth', 'position', 'counterState'
        let count = 0
        let cols = sheetParameters.cols
        let rows = sheetParameters.rows
        let counterSizePX = sheetParameters.counterSizeInPixels
        let ratioMMtoPixels = sheetParameters.ratioMMtoPixels
        let counterMargins = sheetParameters.counterMargins
        let pageMargins = sheetParameters.pageMargins
        let marginLeftPixels = counterMargins[3] * ratioMMtoPixels
        let marginTopPixels = counterMargins[0] * ratioMMtoPixels
        let marginRightPixels = counterMargins[1] * ratioMMtoPixels
        let marginBottomPixels = counterMargins[2] * ratioMMtoPixels
        let pageLeftPixels = pageMargins[3] * ratioMMtoPixels
        let pageTopPixels = pageMargins[0] * ratioMMtoPixels

        let totalCounterPixelsWidth = sheetParameters.counterSizeInPixels + marginLeftPixels + marginRightPixels
        let totalCounterPixelsHeight = sheetParameters.counterSizeInPixels + marginTopPixels + marginBottomPixels
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                obj.number = count
                obj.rearNumber = (cols - col - 1) + (row * cols)
                count++
                obj.xy = { x: col * totalCounterPixelsWidth, y: row * totalCounterPixelsHeight }
                obj.xy.x += marginLeftPixels + pageLeftPixels;
                obj.xy.y += marginTopPixels + pageTopPixels
                obj.centerXy = { x: obj.xy.x + (counterSizePX / 2), y: obj.xy.y + (counterSizePX / 2) }
                obj.pixelsWidth = counterSizePX // not sure I need this field. I can compute this later if any counter gets loaded into this slot.
                obj.position = { col, row }
                obj.rearPosition = { col: cols - col - 1, row }
                obj.counterState = null
                slots.push({ ...obj })
            }
        }

        // the max counters that can fit on a sheet is (not valid, not sure where i'll put the cuttoff yet) 373, if the user chooses 1/2 inch counters.
        // however, if they fill up the sheet, then choose a bigger counter size, the counters
        // towards the end may not have a slot to be painted on. For these, we set the xy values
        // to -1, but still retain the counter data, incase they later reduce the counter size.
        if (count < 1609) {
            for (count; count <= 1609; count++) {
                obj.number = count
                obj.xy = { x: -1, y: -1 }
                obj.centerXy = { x: -1, y: -1 }
                obj.pixelsWidth = -1 // not sure I need this field. I can compute this later if any counter gets loaded into this slot.
                obj.position = { col: -1, row: -1 }
                obj.counterState = null
                slots.push({ ...obj })
            }
        }

        return slots
    }

    useEffect(() => {
        if (state.slots.length > 0) {
            if (state.sheetPaper === null) {
                let _paper = setupSheetPaper()
                actions.sheetPaper(_paper)
                return
            }
            if (state.sheetPaper && sheetParameters) {
                if (changingGuideType) {
                    manageGuideType()
                    setChangingGuideType(false)
                    return
                }
                let hash = Utility.hashCode(JSON.stringify(state.slots) + JSON.stringify(sheetParameters))
                if (hash === sgPaintHash.v) {
                    return
                }
                sgPaintHash.v = hash
                paintSheet()
                setRedoSheet(true)
            }
        }
    }, [state.slots, state.sheetSettings]) // state.sheetPaper, , sheetParameters

    const paintSheet = () => {
        if (state.sheetPaper) {
            state.sheetPaper.clear()
            paintBackground()
            paintSlots()
            paintCuttingGuides()
            manageGuideType()
            paintCounters()
        }
    }

    const paintBackground = () => {
        if( !sheetParameters || !sheetParameters.pageMargins ) {
            console.error('no sheetParameters:', sheetParameters)
            return
        }
        let pageMargins = sheetParameters.pageMargins
        let ratioMMtoPixels = sheetParameters.ratioMMtoPixels
        let pageWidth = sheetParameters.width
        let pageHeight = sheetParameters.height
        let pageLeftPixels = pageMargins[3] * ratioMMtoPixels
        let pageTopPixels = pageMargins[0] * ratioMMtoPixels
        let pageRightPixels = pageMargins[1] * ratioMMtoPixels
        let pageBottomPixels = pageMargins[2] * ratioMMtoPixels




        const p = state.sheetPaper.path("M 0 0 L 25 0 25 25 0 25 M 25 25 L 50 25 50 50 25 50 25 25").attr({
            stroke: "#000",
            strokeWidth: 0,
            fill: "#aaa"
        }).toPattern(0, 0, 50, 50)

        p.attr({ "data-type": "pageMargin" })

        state.sheetPaper.rect(pageLeftPixels, pageTopPixels,
            pageWidth - (pageLeftPixels + pageRightPixels),
            pageHeight - (pageTopPixels + pageBottomPixels)).attr({ fill: "#bbb", "data-type": "pageMargin" })

        state.sheetPaper.rect(pageLeftPixels, pageTopPixels,
            pageWidth - (pageLeftPixels + pageRightPixels),
            pageHeight - (pageTopPixels + pageBottomPixels)).attr({ fill: p, "data-type": "pageMargin" })
        //state.sheetPaper.paper.rect(pageLeftPixels, pageTopPixels, pageWidth - (pageLeftPixels + pageRightPixels), pageHeight - (pageTopPixels + pageBottomPixels)).attr({ id: 'pageMarginTop', stroke: "none", "strokeWidth": 0, fill: p, "data-type": "pageMargin" })
        // let d="M 0 0 L 50 0 50 50 0 50 M 50 50 L 100 50 100 100 50 100 50 50"
        // state.sheetPaper.path(d).attr({stroke: "red", fill: "red", strokeWidth: 1}) 
    }

    const paintCuttingGuides = () => {
        let corners = []
        let currentCuttingGuide = ''
        // let cols = state.sheetSettings.countersColumnsRows[0]
        // let rows = state.sheetSettings.countersColumnsRows[1]
        let cols = sheetParameters.cols
        let rows = sheetParameters.rows

        const drawLines = segments => {
            let linesDrawn = []
            segments.forEach(sg => {
                let x1 = sg.from.leftSide.x
                let y1 = sg.from.leftSide.y
                let x2 = sg.to.leftSide.x
                let y2 = sg.to.leftSide.y
                let path = 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2
                if (!linesDrawn.includes(path)) {
                    linesDrawn.push(path)
                    state.sheetPaper.path(path).attr({
                        stroke: "black", strokeWidth: 2, fill: "none",
                        "data-type": "cuttingGuide" + currentCuttingGuide
                    })
                }
                x1 = sg.from.rightSide.x
                y1 = sg.from.rightSide.y
                x2 = sg.to.rightSide.x
                y2 = sg.to.rightSide.y
                path = 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2
                if (!linesDrawn.includes(path)) {
                    linesDrawn.push(path)
                    state.sheetPaper.path(path).attr({
                        stroke: "black", strokeWidth: 2, fill: "none",
                        "data-type": "cuttingGuide" + currentCuttingGuide
                    })
                }
            })
        }

        const drawHLines = segments => {
            let linesDrawn = []
            segments.forEach(sg => {
                let x1 = sg.from.topSide.x
                let y1 = sg.from.topSide.y
                let x2 = sg.to.topSide.x
                let y2 = sg.to.topSide.y
                let path = 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2
                if (!linesDrawn.includes(path)) {
                    linesDrawn.push(path)
                    state.sheetPaper.path(path).attr({
                        stroke: "black", strokeWidth: 2, fill: "none",
                        "data-type": "cuttingGuide" + currentCuttingGuide
                    })
                }
                x1 = sg.from.bottomSide.x
                y1 = sg.from.bottomSide.y
                x2 = sg.to.bottomSide.x
                y2 = sg.to.bottomSide.y
                path = 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2
                if (!linesDrawn.includes(path)) {
                    linesDrawn.push(path)
                    state.sheetPaper.path(path).attr({
                        stroke: "black", strokeWidth: 2, fill: "none",
                        "data-type": "cuttingGuide" + currentCuttingGuide
                    })
                }
            })
        }

        // also used for edges
        const drawCorners = corners => {
            let x = -1
            let y = -1
            //let dLR = state.sheetSettings.counterMargins[1] + state.sheetSettings.counterMargins[3]
            let dLR = sheetParameters.counterMargins[1] + sheetParameters.counterMargins[3]
            dLR = Utility.roundFloat(dLR, 1)
            dLR = dLR > 15 ? 15 : dLR
            dLR = dLR < 2 ? 2 : dLR
            //let dTB = state.sheetSettings.counterMargins[0] + state.sheetSettings.counterMargins[2]
            let dTB = sheetParameters.counterMargins[0] + sheetParameters.counterMargins[2]
            dTB = Utility.roundFloat(dTB, 1)
            dTB = dTB > 15 ? 15 : dTB
            dTB = dTB < 2 ? 2 : dTB
            x = corners[0].x
            y = corners[0].y
            x = Utility.roundFloat(x, 1)
            y = Utility.roundFloat(y, 1)
            if (x !== 99999999) {
                if (x > -1 && y > -1) {
                    // left top
                    state.sheetPaper.path('M' + (x - dLR) + ' ' + y + ' L ' +
                        x + ' ' + y + ' ' + x + ' ' + (y - dTB)).attr({
                            stroke: "black", "stroke-width": 2, fill: "none",
                            "data-type": "cuttingGuide" + currentCuttingGuide
                        })
                }
            }

            x = corners[1].x
            y = corners[1].y
            x = Utility.roundFloat(x, 1)
            y = Utility.roundFloat(y, 1)
            if (x !== 99999999) {
                if (x > -1 && y > -1) {
                    let dp = "M "
                    dp += x + ' ' + (y - dTB) + ' L ' +
                        x + ' ' + y + ' ' +
                        (x + dLR) + ' ' + y
                    state.sheetPaper.path(dp).attr({
                        stroke: "black", "stroke-width": 2, fill: "none",
                        "data-type": "cuttingGuide" + currentCuttingGuide
                    })
                }
            }

            x = corners[2].x
            y = corners[2].y
            x = Utility.roundFloat(x, 1)
            y = Utility.roundFloat(y, 1)
            if (x !== 99999999) {
                if (x > -1 && y > -1) {
                    // bottom right
                    state.sheetPaper.path('M' + (x + dLR) + ' ' + y + ' L ' +
                        x + ' ' + y + ' ' + x + ' ' + (y + dTB)).attr({
                            stroke: "black", "stroke-width": 2, fill: "none",
                            "data-type": "cuttingGuide" + currentCuttingGuide
                        })
                }
            }

            x = corners[3].x
            y = corners[3].y
            x = Utility.roundFloat(x, 1)
            y = Utility.roundFloat(y, 1)
            if (x !== 99999999) {
                if (x > -1 && y > -1) {
                    // bottom left
                    state.sheetPaper.path('M' + (x - dLR) + ' ' + y + ' L ' +
                        x + ' ' + y + ' ' + x + ' ' + (y + dTB)).attr({
                            stroke: "black", "stroke-width": 2, fill: "none",
                            "data-type": "cuttingGuide" + currentCuttingGuide
                        })
                }
            }
        }



        // remove previous guidelines, if any
        // draw new cutting guides
        currentCuttingGuide = 'Corners'
        state.slots.forEach(sl => {
            if (sl.xy.x > -1) {
                corners = []
                let x = sl.xy.x
                let y = sl.xy.y
                corners.push({ x, y })
                x = x + sl.pixelsWidth
                corners.push({ x, y })
                y = y + sl.pixelsWidth
                corners.push({ x, y })
                x = sl.xy.x
                corners.push({ x, y })
                drawCorners(corners)
            }
        })

        currentCuttingGuide = 'Edges'

        corners = []

        // remove previous guidelines, if any
        // draw new cutting guides
        state.slots.forEach(sl => {
            if (sl.xy.x > -1) {

                if (sl.position.col === 0 && sl.position.row === 0) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x, y })
                    x = x + sl.pixelsWidth
                    corners.push({ x, y })
                    y = y + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    x = sl.xy.x
                    corners.push({ x, y })
                    drawCorners(corners)
                }
                if (sl.position.col > 0 && sl.position.col < cols - 1 && sl.position.row === 0) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x, y })
                    x = x + sl.pixelsWidth
                    corners.push({ x, y })
                    y = y + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    x = sl.xy.x
                    corners.push({ x: -1, y: -1 })
                    drawCorners(corners)
                }
                if (sl.position.col === cols - 1 && sl.position.row === 0) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x, y })
                    x = x + sl.pixelsWidth
                    corners.push({ x, y })
                    y = y + sl.pixelsWidth
                    corners.push({ x, y })
                    x = sl.xy.x
                    corners.push({ x: -1, y: -1 })
                    drawCorners(corners)
                }

                if (sl.position.col === 0 && sl.position.row > 0 && sl.position.row < rows - 1) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x, y })
                    x = x + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    y = y + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    x = sl.xy.x
                    corners.push({ x, y })
                    drawCorners(corners)
                }
                if (sl.position.col === cols - 1 && sl.position.row > 0 && sl.position.row < rows - 1) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x: 99999999, y: 99999999 })
                    x = x + sl.pixelsWidth
                    corners.push({ x, y })
                    y = y + sl.pixelsWidth
                    corners.push({ x, y })
                    x = sl.xy.x
                    corners.push({ x: 99999999, y: 99999999 })
                    drawCorners(corners)
                }
                if (sl.position.col === cols - 1 && sl.position.row === rows - 1) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x: -1, y: -1 })
                    x = x + sl.pixelsWidth
                    corners.push({ x, y })
                    y = y + sl.pixelsWidth
                    corners.push({ x, y })
                    x = sl.xy.x
                    corners.push({ x, y })
                    drawCorners(corners)
                }
                if (sl.position.col === 0 && sl.position.row === rows - 1) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x, y })
                    x = x + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    y = y + sl.pixelsWidth
                    corners.push({ x, y })
                    x = sl.xy.x
                    corners.push({ x, y })
                    drawCorners(corners)
                }
                if (sl.position.col > 0 && sl.position.col < cols - 1 && sl.position.row === rows - 1) {
                    corners = []
                    let x = sl.xy.x
                    let y = sl.xy.y
                    corners.push({ x: -1, y: -1 })
                    x = x + sl.pixelsWidth
                    corners.push({ x: -1, y: -1 })
                    y = y + sl.pixelsWidth
                    corners.push({ x, y })
                    x = sl.xy.x
                    corners.push({ x, y })
                    drawCorners(corners)
                }

            }
        })


        currentCuttingGuide = 'Lines'

        let segmentsVertical = []
        let segmentsHorizontal = []
        //let dM = state.sheetSettings.counterMargins[1] + state.sheetSettings.counterMargins[3]
        let dM = sheetParameters.counterMargins[1] + sheetParameters.counterMargins[3]
        dM = Utility.roundFloat(dM, 1)
        dM = dM < 2 ? 2 : dM

        for (let c = 0; c < cols; c++) {
            segmentsVertical.push(
                {
                    from: {
                        leftSide: {
                            col: c, row: 0, x: null, y: null
                        },
                        rightSide: {
                            col: c, row: 0, x: null, y: null
                        }
                    },
                    to: {
                        leftSide: {
                            col: c, row: (rows - 1), x: null, y: null
                        },
                        rightSide: {
                            col: c, row: (rows - 1), x: null, y: null
                        }
                    }
                }
            )
        }

        for (let r = 0; r < rows; r++) {
            segmentsHorizontal.push(
                {
                    from: {
                        topSide: {
                            col: 0, row: r, x: null, y: null
                        },
                        bottomSide: {
                            col: 0, row: r, x: null, y: null
                        }
                    },
                    to: {
                        topSide: {
                            col: (cols - 1), row: r, x: null, y: null
                        },
                        bottomSide: {
                            col: (cols - 1), row: r, x: null, y: null
                        }
                    }
                }
            )
        }

        state.slots.forEach(sl => {
            if (sl.xy.x > -1) {

                let fromSegment = segmentsVertical.find(sh => sh.from.leftSide.col === sl.position.col &&
                    sh.from.leftSide.row === sl.position.row)
                if (fromSegment) {
                    fromSegment.from.leftSide.x = sl.xy.x
                    fromSegment.from.leftSide.y = sl.xy.y - dM
                    fromSegment.from.rightSide.x = sl.xy.x + sl.pixelsWidth
                    fromSegment.from.rightSide.y = sl.xy.y - dM
                }
                let toSegment = segmentsVertical.find(sh => sh.to.leftSide.col === sl.position.col &&
                    sh.to.leftSide.row === sl.position.row)
                if (toSegment) {
                    toSegment.to.leftSide.x = sl.xy.x
                    toSegment.to.leftSide.y = sl.xy.y + sl.pixelsWidth + dM
                    toSegment.to.rightSide.x = sl.xy.x + sl.pixelsWidth
                    toSegment.to.rightSide.y = sl.xy.y + sl.pixelsWidth + dM
                }

                fromSegment = segmentsHorizontal.find(sh => sh.from.topSide.col === sl.position.col &&
                    sh.from.topSide.row === sl.position.row)
                if (fromSegment) {
                    fromSegment.from.topSide.x = sl.xy.x - dM
                    fromSegment.from.topSide.y = sl.xy.y
                    fromSegment.from.bottomSide.x = sl.xy.x - dM
                    fromSegment.from.bottomSide.y = sl.xy.y + sl.pixelsWidth
                }
                toSegment = segmentsHorizontal.find(sh => sh.to.bottomSide.col === sl.position.col &&
                    sh.to.bottomSide.row === sl.position.row)
                if (toSegment) {
                    toSegment.to.topSide.x = sl.xy.x + dM + sl.pixelsWidth
                    toSegment.to.topSide.y = sl.xy.y
                    toSegment.to.bottomSide.x = sl.xy.x + dM + sl.pixelsWidth
                    toSegment.to.bottomSide.y = sl.xy.y + sl.pixelsWidth
                }

            }
        })
        segmentsVertical = segmentsVertical.filter(sg => sg.from.leftSide.x !== null && sg.from.rightSide.x !== null && sg.from.leftSide.y !== null && sg.from.rightSide.y !== null)
        segmentsHorizontal = segmentsHorizontal.filter(sg => sg.from.topSide.x !== null && sg.from.topSide.x !== null && sg.from.bottomSide.y !== null && sg.from.bottomSide.y !== null)
        drawLines(segmentsVertical)
        drawHLines(segmentsHorizontal)

    }

    const paintCounters = () => {
        let slotsToPaint = state.slots.filter(ssa => ssa.counterState !== null && ssa.xy.x !== -1)
        // clear slots  
        state.slots.forEach(sup => {
            let possibleGroupId = 'group_' + sup.number
            let foundGroup = state.sheetPaper.select("#" + possibleGroupId);
            while (foundGroup) {
                foundGroup.remove()
                foundGroup = state.sheetPaper.select("#" + possibleGroupId);
            }
        })
        slotsToPaint.forEach(stp => paintCounter(stp))
    }

    const constructSheetSizes = (useCounterSizeMM, printableAreaMM) => {
        let domWidth = -1
        let domHeight = -1
        let counterSizeInPixels = -1
        let ratioMMtoPixels = -1
        let sheetDisplayEle = document.getElementById('sheetDisplayContainer')
        if (sheetDisplayEle) {
            const sheetBbox = sheetDisplayEle.getBoundingClientRect()
            let ratio = printableAreaMM[0] / printableAreaMM[1]
            sheetDisplayEle.style.aspectRatio = ratio
            domWidth = sheetBbox.width
            domHeight = sheetBbox.height
            ratioMMtoPixels = domWidth / printableAreaMM[0]
            counterSizeInPixels = Utility.roundFloat(useCounterSizeMM * ratioMMtoPixels, 10)

            return {
                domWidth,
                domHeight,
                counterSizeInPixels,
                counterSizeMM: useCounterSizeMM,
                ratioMMtoPixels
            }
        }
        console.warn('could not get reference to sheetDisplayContainer in Dom')
        return null
    }

    const nextAvailableSlot = () => {
        if (!sheetSide || sheetSide === 'front') {
            return state.slots.find(ls => ls.counterState === null)
        }
        else {
            let orderedSlots = JSON.parse(JSON.stringify(state.slots))
            orderedSlots.sort((a, b) => a.rearNumber - b.rearNumber)
            let slot = orderedSlots.find(ls => ls.counterState === null)
            let realSlot = state.slots.find(sl => sl.number === slot.number)
            return realSlot
        }
    }


    useEffect(() => {
        // slots: ['number', 'number', 'xy', 'centerXy', 'pixelsWidth', 'position', 'counterState']
        if (state.moveCounterToSheet) {
            //let freeSlot = state.slots.find(ls => ls.counterState === null)
            let freeSlot = nextAvailableSlot()
            if (freeSlot) {
                freeSlot.counterState = state.moveCounterToSheet
                freeSlot.counterState.package.counterSideActive.active = sheetSide
                actions.slots([...state.slots])
            }
        }
    }, [state.moveCounterToSheet])

    const popupContextMenuForCounterAtSlot = slot => {
        // if clicking again on the same counter, assume the user wants to make the menu go away.
        if (sgContextMenuActive.value !== null) {
            if (sgContextMenuActive.value.number === slot.number) {
                sgContextMenuActive.value = null
                return
            }
        }

        sgContextMenuActive.value = slot
        positionContextMenu(slot.xy.x, slot.xy.y)
    }

    const positionContextMenu = (x, y) => {
        let posX = x + sheetParameters.counterSizeInPixels
        let winWidth = window.innerWidth
        // position to the left if its partially offwindow.
        if( posX + 90 > winWidth ) {
           posX = x  - 96
        }
        setContextBox({ left: posX + 'px', top: y + 'px' })
    }

    const dragStartCounter = evt => {
        let groupId = evt.node.id
        let g = state.sheetPaper.select("#" + groupId);
        g.remove()
        state.sheetPaper.append(g) // make it last painted so it stays "above" the other groups while dragging around.


        let counterNumber = Number(groupId.replace('group_', ''))
        let slots = [...state.slots]
        let movedCounterSlot = slots.find(ss => ss.number === counterNumber)
        if (movedCounterSlot) {
            draggingSlot.value = { g, slot: movedCounterSlot, overRect: null, dragged: false }
        }
    }

    var dragMoveCounter = (evt, dx, dy) => {
        if (!draggingSlot.value) {
            return
        }
        sgContextMenuActive.value = null
        draggingSlot.value.dragged = true
        // let g = state.sheetPaper.select("#" + evt.node.id);
        // g.remove()
        // state.sheetPaper.append(g) // make it last painted so it stays "above" the other groups while dragging around.
        //let overSlot = closestSlotToPoint(slots, { x: centerX, y: centerY })
        let g = draggingSlot.value.g
        let currentMatrix = g.transform().localMatrix;
        let movedX = currentMatrix.e
        let movedY = currentMatrix.f
        let centerX = (movedX + (sheetParameters.counterSizeInPixels / 2)) // sheetParameters.ratioMMtoPixels )
        let centerY = (movedY + (sheetParameters.counterSizeInPixels / 2)) // sheetParameters.ratioMMtoPixels )
        let candidateSlot = closestSlotToPoint(state.slots, { x: centerX, y: centerY })

        let rectEle = state.sheetPaper.select('#' + 'rect_' + candidateSlot.number)
        if (draggingSlot.value.overRect) {
            if (draggingSlot.value.overRect !== rectEle) {
                draggingSlot.value.overRect.attr({ fill: "#eee" })
            }
            else {
                if (candidateSlot.counterState) {
                    rectEle.attr({ fill: "#eee" })
                }
                else {
                    rectEle.attr({ fill: "#ccf" })
                }
            }
        }

        let rectEleHome = state.sheetPaper.select('#' + 'rect_' + draggingSlot.value.slot.number)
        rectEleHome.attr({ fill: "#fbc" })

        draggingSlot.value.overRect = rectEle
    }

    const dragStopCounter = evt => {
        if (!draggingSlot.value) {
            return
        }
        if (draggingSlot.value.dragged === false) {
            popupContextMenuForCounterAtSlot(draggingSlot.value.slot)
            draggingSlot.value = null
            return;
        }
        let rectEleHome = state.sheetPaper.select('#' + 'rect_' + draggingSlot.value.slot.number)
        rectEleHome.attr({ fill: "#eee" })
        let groupId = evt.node.id
        let counterNumber = Number(groupId.replace('group_', ''))
        let g = state.sheetPaper.select("#" + evt.node.id);
        let currentMatrix = g.transform().localMatrix;
        let movedX = currentMatrix.e
        let movedY = currentMatrix.f
        // get center of moved counter
        let slots = [...state.slots]
        let movedCounterSlot = slots.find(ss => ss.number === counterNumber)

        let centerX = (movedX + (sheetParameters.counterSizeInPixels / 2)) // sheetParameters.ratioMMtoPixels )
        let centerY = (movedY + (sheetParameters.counterSizeInPixels / 2)) // sheetParameters.ratioMMtoPixels )
        //state.sheetPaper.circle(centerX,centerY,10).attr({fill: "none", stroke: "red", strokeWidth: 2})
        // which slot is closest to the center of the moved counter?

        let candidateSlot = closestSlotToPoint(slots, { x: centerX, y: centerY })
        if (candidateSlot) {
            if (candidateSlot.counterState) {
                // if already occupied, send the moved counter back to where it came from.
                let backHomeMatrix = new Snap.Matrix()
                backHomeMatrix.translate(movedCounterSlot.xy.x, movedCounterSlot.xy.y)
                g.transform(backHomeMatrix)
            }
            else {
                candidateSlot.counterState = { ...movedCounterSlot.counterState }
                movedCounterSlot.counterState = null
                actions.slots(slots)
            }
        }
        else {
            console.warn('could not find a slot that was close to x:', centerX, 'y:', centerY)
        }
        draggingSlot.value = null
    }

    const closestSlotToPoint = (slots, point) => {
        let arr = slots.map(ss => {
            let distance = Utility.distanceTwoPoints(ss.centerXy, point)
            return { slotNumber: ss.number, distance }
        })
        arr = arr.sort((a, b) => {
            if (a.distance > b.distance) {
                return 1;
            }
            if (a.distance < b.distance) {
                return -1;
            }
            return 0;
        })

        return slots.find(ss => ss.number === arr[0].slotNumber)
    }

    const paintCounter = slot => {
        if (state.sheetPaper === 'reset') {
            return
        }
        if (!sheetParameters) {
            return
        }
        let buttonPressed = -1
        //let svg = slot.counterState[sheetSide === 'front' ? 'frontSvg' : 'rearSvg']
        let svg = ''
        if( sheetSide === 'front' ) {
           svg = slot.counterState.frontSvg   
        }
        else {
           svg = slot.counterState.rearSvg
        }
        if (!svg) {
            console.warn('check custom svg: ', slot.counterState)
            return
        }
        let idStartPos = svg.indexOf( 'id="combined_' )
        let idEndPos = svg.indexOf( '" ', idStartPos)
        let idStr = svg.substring(idStartPos + 4, idEndPos)
        svg = svg.replace(idStr, 'slot_' + slot.number)
        svg = svg.replace('width="100%" height="100%"', 'width="' + sheetParameters.counterSizeInPixels + '" height="' + sheetParameters.counterSizeInPixels + '"')
        let parsed = Snap.parse(svg)
        if (parsed) {
            let g = state.sheetPaper.group()
            g.append(parsed)
            g.attr({ id: 'group_' + slot.number })

            let currentMatrix = g.transform().localMatrix;
            let addMatrix = new Snap.Matrix()
            addMatrix.translate(slot.xy.x, slot.xy.y)
            addMatrix.add(currentMatrix)
            g.transform(addMatrix)

            var move = function (dx, dy) {
                if (buttonPressed !== 0) {
                    return
                }
                this.attr({
                    transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
                });
                dragMoveCounter(this, dx, dy)
            }

            // var move = function (dx, dy) {
            //     dragMoveCounter(this, dx, dy)
            // }

            var start = function (x, y, evt) {
                if (evt.button !== 0) {
                    return // only operate on left clicks. Leave right click to browser's use.
                }
                buttonPressed = 0
                this.data('origTransform', this.transform().local);
                dragStartCounter(this)
            }
            var stop = function () {
                if (buttonPressed !== 0) {
                    return
                }
                dragStopCounter(this)
            }

            g.drag(move, start, stop);

        }
    }

    useEffect(() => {
        // since windowResize may be emitted with the same values, we need to ignore it when the values are the same.
        if (state.windowResize.width === sgPreviousWindowResize.v.width &&
            state.windowResize.height === sgPreviousWindowResize.v.height) {
            return
        }

        sgPreviousWindowResize.v.width = state.windowResize.width
        sgPreviousWindowResize.v.height = state.windowResize.height

        function throttle(func, wait) {
            let waiting = false;
            return function () {
                if (waiting) {
                    return;
                }
                waiting = true;
                setTimeout(() => {
                    func.apply(this, arguments);
                    waiting = false;
                }, wait);
            };
        }

        if (state.windowResize && state.windowResize.width > -1) {
            sgContextMenuActive.value = null
            if (state.sheetPaper) {
                throttle(evt => {
                    let _sheetParameters = computeSheetParameters(sheetParameters)
                    if (sgLastSheetParameters.v.width !== _sheetParameters.width ||
                        sgLastSheetParameters.v.height !== _sheetParameters.height ||
                        sgLastSheetParameters.v.counterSizeInPixels !== _sheetParameters.counterSizeInPixels ||
                        sgLastSheetParameters.v.cols !== _sheetParameters.cols ||
                        sgLastSheetParameters.v.rows !== _sheetParameters.rows
                    ) {
                        setSheetParameters(_sheetParameters)
                        sgLastSheetParameters.value = { ..._sheetParameters }
                    }
                }, 1000)();
            }
        }
    }, [state.windowResize])

    const computeCountersMaxColsRows = (settings, counterMargins, pageMargins) => {
        let { domWidth, domHeight, ratioMMtoPixels, counterSizeInPixels } = settings
        let marginLeftPixels = counterMargins[3] * ratioMMtoPixels
        let marginTopPixels = counterMargins[0] * ratioMMtoPixels
        let marginRightPixels = counterMargins[1] * ratioMMtoPixels
        let marginBottomPixels = counterMargins[2] * ratioMMtoPixels
        let pageLeftPixels = pageMargins[3] * ratioMMtoPixels
        let pageTopPixels = pageMargins[0] * ratioMMtoPixels
        let pageRightPixels = pageMargins[1] * ratioMMtoPixels
        let pageBottomPixels = pageMargins[2] * ratioMMtoPixels
        domWidth -= pageLeftPixels
        domWidth -= pageRightPixels
        domHeight -= pageTopPixels
        domHeight -= pageBottomPixels
        let totalCounterPixelsWidth = counterSizeInPixels + marginLeftPixels + marginRightPixels
        let totalCounterPixelsHeight = counterSizeInPixels + marginTopPixels + marginBottomPixels
        let maxColumnCount = Math.floor(domWidth / totalCounterPixelsWidth)
        if (Math.floor((domWidth / totalCounterPixelsWidth) + 0.01) > maxColumnCount) {
            maxColumnCount = Math.floor((domWidth / totalCounterPixelsWidth) + 0.01)
        }

        let maxRowCount = Math.floor(domHeight / totalCounterPixelsHeight)
        if (Math.floor((domHeight / totalCounterPixelsHeight) + 0.01) > maxRowCount) {
            maxRowCount = Math.floor((domHeight / totalCounterPixelsHeight) + 0.01)
        }
        return { cols: maxColumnCount, rows: maxRowCount }
    }

    useEffect(() => {
        let _paper = setupSheetPaper()
        actions.sheetPaper(_paper)
    }, [state.layoutInline])

    const paintSlots = () => {
        if (!state.sheetPaper || !sheetParameters) {
            return
        }

        let paper = state.sheetPaper
        let sheetBbox = null
        // assume the screen width is 8 inches
        let sheetDisplayEle = document.getElementById('sheetDisplayContainer')
        if (sheetDisplayEle) {
            sheetBbox = sheetDisplayEle.getBoundingClientRect()
        }
        if (!sheetBbox) {
            console.warn('no sheetDisplayEle:', sheetDisplayEle)
            return
        }

        let counterSizePX = sheetParameters.counterSizeInPixels
        state.slots.forEach((sl, index) => {
            let x = sl.xy.x
            let y = sl.xy.y
            if (x > -1 && y > -1) { // only for slots that fit on the sheet.
                let cx = sl.centerXy.x
                let cy = sl.centerXy.y

                let backgroundColor = '#ddebdd'
                if (!sheetSide || sheetSide === 'front') {
                    backgroundColor = '#eee'
                }

                paper.rect(x, y, counterSizePX, counterSizePX).attr({ id: 'rect_' + sl.number, stroke: "black", "strokeWidth": 1, fill: backgroundColor, "data-type": "holder" })

                let colRowToDraw = "col " + (sl.rearPosition.col + 1) + " row " + (sl.rearPosition.row + 1)
                let counterNumberToDraw = sl.rearNumber + 1
                let sideText = 'REAR'
                if (!sheetSide || sheetSide === 'front') {
                    colRowToDraw = "col " + (sl.position.col + 1) + " row " + (sl.position.row + 1)
                    counterNumberToDraw = sl.number + 1
                    sideText = 'FRONT'
                }

                paper.text(cx, cy + (counterSizePX * 0.29), colRowToDraw).attr(
                    {
                        "textAnchor": "middle",
                        "dominant-baseline": "central",
                        "fontSize": counterSizePX * 0.14,
                        "fontWeight": "normal",
                        "fontFamily": "sans-serif",
                        stroke: "none",
                        fill: "#555",
                        "data-type": "holderText"
                    })


                paper.text(cx, cy, counterNumberToDraw + '').attr(
                    {
                        "textAnchor": "middle",
                        "dominant-baseline": "central",
                        "fontSize": counterSizePX / 4,
                        "fontWeight": "normal",
                        "fontFamily": "sans-serif",
                        stroke: "none",
                        fill: "#555",
                        "data-type": "holderText"
                    })

                paper.text(cx, cy - (counterSizePX * 0.29), sideText).attr(
                    {
                        "textAnchor": "middle",
                        "dominant-baseline": "central",
                        "fontSize": counterSizePX / 6,
                        "fontWeight": "normal",
                        "fontFamily": "sans-serif",
                        stroke: "none",
                        fill: "#555",
                        "data-type": "holderText"
                    })

            }

        })
    }

    const onEdit = evt => {
        if (sgContextMenuActive.value) {
            if (sgContextMenuActive.value.counterState) {
                actions.counterLoadFromSheet({...sgContextMenuActive.value.counterState.package})
            }
            sgContextMenuActive.value = null
        }
    }

    const onDelete = evt => {
        let slots = [...state.slots]
        let slot = slots.find(ss => ss.number === sgContextMenuActive.value.number)
        if (slot) {
            slot.counterState = null
            actions.slots(slots)
        }
        sgContextMenuActive.value = null
    }

    const changeSheetParameters = sp => {
        if (sp) {
            setSheetParameters(sp)
        }
    }

    const changeCounterSizeMM = (cmm, custom = false) => {
        let useSheetParameters = { ...sheetParameters }
        useSheetParameters.counterSizeMM = cmm
        useSheetParameters.useCounterCustomSize = custom
        useSheetParameters.counterCustomSize = custom ? cmm : ''
        let _sheetParameters = computeSheetParameters(useSheetParameters)
        setSheetParameters(_sheetParameters)
    }

    const changeCounterMargins = margins => {
        let useSheetParameters = { ...sheetParameters }
        useSheetParameters.counterMargins = margins

        let _sheetParameters = computeSheetParameters(useSheetParameters)
        setSheetParameters(_sheetParameters)
    }

    const changePageMargins = margins => {
        let useSheetParameters = { ...sheetParameters }
        useSheetParameters.pageMargins = margins
        let _sheetParameters = computeSheetParameters(useSheetParameters)

        setSheetParameters(_sheetParameters)
    }

    const changeSheetMenuControlsOpened = opened => {
        setSheetMenuControlsOpened(opened)
    }
    const changeSheetMenuOperationsOpened = opened => {
        setSheetMenuOperationsOpened(opened)
    }

    const changeGuideType = guideType => {

        if (guideType) {
            if (changingGuideType === guideType) {
                return
            }
            setChangingGuideType(guideType)
        }
    }

    useEffect(() => {
        if (changingGuideType) {
            setSheetParameters({ ...sheetParameters, guideType: changingGuideType })
        }
    }, [changingGuideType])

    const manageGuideType = () => {
        let guide = sheetParameters.guideType
        let guidesToShow = []
        let guidesToHide = []
        if (guide === 'none') {
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideCorners"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideEdges"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideLines"]`))
        }
        if (guide === 'corners') {
            guidesToShow = guidesToShow.concat(...document.querySelectorAll(`[data-type="cuttingGuideCorners"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideEdges"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideLines"]`))
        }
        if (guide === 'edges') {
            guidesToShow = guidesToShow.concat(...document.querySelectorAll(`[data-type="cuttingGuideEdges"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideCorners"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideLines"]`))
        }
        if (guide === 'lines') {
            guidesToShow = guidesToShow.concat(...document.querySelectorAll(`[data-type="cuttingGuideLines"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideCorners"]`))
            guidesToHide = guidesToHide.concat(...document.querySelectorAll(`[data-type="cuttingGuideEdges"]`))
        }
        guidesToHide.forEach(guide => guide ? guide.style.opacity = 0 : null)
        guidesToShow.forEach(guide => guide ? guide.style.opacity = 1 : null)
    }

    const changePrintableArea = area => {
        if (!area ||
            !Array.isArray(area) ||
            area.length !== 2 ||
            !Utility.isNumeric(area[0]) ||
            !Utility.isNumeric(area[1]) ||
            area[1] === 0) {
            return
        }
        let ratio = area[0] / area[1]
        let ele = document.getElementById('sheetDisplayContainer')
        if (ele) {
            ele.style.aspectRatio = ratio
            const sheetBbox = ele.getBoundingClientRect()
            let _sheetParameters = {
                ...sheetParameters,
                printableAreaMM: area, width: sheetBbox.width, height: sheetBbox.height
            }
            _sheetParameters = computeSheetParameters(_sheetParameters)
            setSheetParameters(_sheetParameters)
        }
    }

    useEffect(() => {
        if (sheetAspectRatioChangedSheetParameters) {
            setSheetParameters(sheetAspectRatioChangedSheetParameters)
        }
        setSheetAspectRatioChangedSheetParameters(false)
    }, [sheetAspectRatioChangedSheetParameters])

    const removeCounterPaints = () => {
        state.slots.forEach(sup => {
            let possibleGroupId = 'group_' + sup.number
            let foundGroup = state.sheetPaper.select("#" + possibleGroupId);
            while (foundGroup) {
                foundGroup.remove()
                foundGroup = state.sheetPaper.select("#" + possibleGroupId);
            }
        })
    }

    useEffect(() => {
        if (state.loadSheet && state.loadSheet.sheetSettings && state.loadSheet.slots) {
            actions.sheetSettings(state.loadSheet.sheetSettings)
            actions.slots(state.loadSheet.slots)
            setRedoSheet(true)
        }
    }, [state.loadSheet])

    useEffect(() => {
        if (redoSheet) {
            if (state.sheetSettings && !Utility.emptyCheck(state.sheetSettings)) {
                setupSheetParameters()
                setRedoSheet(false)
            }
        }

    }, [redoSheet])

    const changeSheetSide = side => {
        if (side !== sheetSide) {
            setSheetSide(side)
        }
    }

    useEffect(() => {
        if (sheetSide === 'front' || sheetSide === 'rear') {
            if (sheetAlreadyCorrect() === false) {
                reverseSheet()
            }
        }
    }, [sheetSide])

    const sheetAlreadyCorrect = () => {
        let slotsOccupied = state.slots.filter(ss => ss.counterState !== null)
        if (slotsOccupied.length > 0) {
            let slot0 = slotsOccupied[0]
            if (slot0.counterState.package.counterSideActive.active === sheetSide) {
                return true
            }
        }

        return false
    }

    const reverseSheet = () => {
        let targetSlots = JSON.parse(JSON.stringify(state.slots))
        targetSlots.filter(ts => ts.counterState = null)
        let slotsOccupied = state.slots.filter(ss => ss.counterState !== null)
        if (slotsOccupied.length === 0) {
            paintSheet()
            return
        }
        slotsOccupied.forEach(so => {
            if (sheetSide === 'rear') {
                let targetNumber = so.rearNumber
                let targetSlot = targetSlots.find(ts => ts.number === targetNumber)
                if (targetSlot) {
                    targetSlot.counterState = so.counterState
                    targetSlot.counterState.package.counterSideActive.active = 'rear'
                }
            }
            if (sheetSide === 'front') {
                let targetNumber = so.number
                let targetSlot = targetSlots.find(ts => ts.rearNumber === targetNumber)
                if (targetSlot) {
                    targetSlot.counterState = so.counterState
                    targetSlot.counterState.package.counterSideActive.active = 'front'
                }
            }
        })
        actions.slots(targetSlots)
        // let _slots = [...state.slots]
        // _slots.forEach( slot => {
        //     let foundSlot = slotsOccupied.find( so => so.slotNumber === slot.slotNumber )
        //     if( foundSlot ) {
        //         let reverseSlotNumber = sheetSide === 'rear' ? foundSlot.rearNumber : foundSlot.number
        //         moveCounterFromSlotToSlot( foundSlot )
        //     }
        // }) 
    }

    const moveCounterFromSlotToSlot = (from, to) => {
        if (state.slots && state.slots.length > 0 && state.slots[from] && state.slots[to]) {
            let _slots = [...state.slots]
            let counterState = _slots[from].counterState
            if (counterState) {
                counterState.package.counterSideActive.active = sheetSide
                _slots[to].counterState = JSON.parse(JSON.stringify(counterState)) 
                _slots[from].counterState = null
                actions.slots(_slots)
            }
        }
    }

    return <div className="sheet">
        {sheetParameters ?
            <div className={sheetMenuControlsOpened || sheetMenuOperationsOpened ? 'sheet-menus column' : 'sheet-menus row'}>
                <SheetControls sheetParameters={sheetParameters}
                    changeSheetParameters={changeSheetParameters}
                    changeCounterSizeMM={changeCounterSizeMM}
                    changeCounterMargins={changeCounterMargins}
                    changePageMargins={changePageMargins}
                    changeSheetMenuControlsOpened={changeSheetMenuControlsOpened}
                    changeSheetSide={changeSheetSide}
                />

                <SheetOperations sheetParameters={sheetParameters}
                    changeGuideType={changeGuideType}
                    changeSheetMenuOperationsOpened={changeSheetMenuOperationsOpened}
                    changePrintableArea={changePrintableArea}
                />
            </div>
            : ''}
        <div id="sheetContainer" className="sheet-container">
            <div id="sheetDisplayContainer" ref={sheetDisplayRef} className="sheet-display"><svg ref={svgRef} id="sheetDisplay" className="sheet-display-svg" />
                {sgContextMenuActive.value ?
                    <div style={{ left: `${contextBox.left}`, top: `${contextBox.top}` }} id="sheet_context_menu" onClick={evt => evt.preventDefault()} className="context-menu">
                        <div id="sheet_context_menu2">
                            <button id="sheet_context_menuEdit" className="standard-button blue" onClick={onEdit}>edit</button>
                            <button id="sheet_context_menuDelete" className="standard-button red" onClick={onDelete}>delete</button>
                        </div>
                    </div>
                    : ''}
            </div>
        </div>

    </div>
}
export default Sheet;
{/* <svg id="sheetDisplayDragLayer" className="drag-layer" /> */ }